import {Box, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BackupIcon} from '../../../assets/icons/backup.svg';
// import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import {ReactComponent as OptionIcon} from '../../../assets/icons/server-option.svg';
import Typography from '../../../components/UI/Typography';
import {Instance} from '../../../stores/Instance';
import {BENEFIT_BACKUP_ID} from '../../../stores/ServerOptions';
import OverviewItem from './OverviewItem';

interface IProps {
  instance: Instance;
}

const ServerBenefits: React.FC<IProps> = ({instance}) => {
  const {t} = useTranslation();
  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="label3" sx={(theme) => ({marginBottom: theme.spacing(3)})}>
        {t('server_info_overview_additional_services_title')}
      </Typography>

      <Grid container spacing={2} alignSelf="baseline">
        {instance.plan?.benefits.map(({id, title}) => (
          <Grid key={id} item xs={12}>
            <OverviewItem
              value={
                <>
                  <div className="border-icon border-icon--success">
                    {id === BENEFIT_BACKUP_ID ? <BackupIcon /> : <OptionIcon />}
                  </div>

                  <Typography variant="body2">{title}</Typography>
                </>
              }
            />
          </Grid>
        ))}
      </Grid>

      {/* <Button
        sx={{marginTop: theme.spacing(2)}}
        variant="outlined"
        size="large"
        startIcon={<PlusIcon style={{fill: theme.palette.body.primary}} />}
      >
        {t('server_info_overview_additional_services_add_button')}
      </Button> */}
    </Box>
  );
};

export default ServerBenefits;
