import {alpha, Box, Button, Link, styled, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import CookieDarkIcon from '../../assets/image-icons/cookie-dark.svg';
import CookieIcon from '../../assets/image-icons/cookie.svg';
import {useStore} from '../../stores/AppStore';
import {acceptAllCookies, rejectCookieSelection} from './cookiesStateManager';

const Container = styled(Box)(({theme}) => ({
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.backgroundPrimary,
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '16px',
  boxShadow: `${alpha('#000', 0.2)} 2px 2px 18px 0`,
  zIndex: 99999,
  maxWidth: '460px',

  lineHeight: 1,
}));

interface ConsentDialogProps {
  onAcceptAllClick?: () => void;
  onCustomizeClick?: () => void;
  onRejectAllClick?: () => void;
  onCloseClick?: () => void;
}

export const ConsentDialog = observer((props: ConsentDialogProps) => {
  const {lightThemeChosen} = useStore();
  const theme = useTheme();

  const handleAcceptAllClick = () => {
    acceptAllCookies();
    props.onAcceptAllClick?.();
  };

  const handleRejectAllClick = () => {
    rejectCookieSelection();
    props.onRejectAllClick?.();
  };

  return (
    <Container>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <img src={lightThemeChosen ? CookieIcon : CookieDarkIcon} alt="" width={32} height={32} />

        <Button
          sx={{marginLeft: 'auto', minWidth: 34}}
          onClick={props.onCloseClick}
          size="small"
          variant="outlined"
          name="close-button"
          aria-label="Close dialog"
        >
          <CloseIcon style={{fill: theme.palette.body.primary}} />
        </Button>
      </Box>

      <div>
        <Typography variant="subtitle1" sx={{marginTop: theme.spacing(5)}}>
          Your Privacy Matters to Us
        </Typography>
        <Typography variant="body2" sx={{marginTop: theme.spacing(2)}}>
          We use cookies to enhance your experience, analyze site traffic, and deliver personalized content. You can
          accept all cookies, customize them to your preference, or reject non-essential ones.
        </Typography>
        <Typography variant="body2" sx={{marginTop: theme.spacing(2)}}>
          <span>Please read our</span>
          <Link
            className="color-brand-primary"
            sx={{marginX: theme.spacing(1)}}
            href="https://landing.crypadvise.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <span>for more details.</span>
        </Typography>
      </div>

      <Box sx={{display: 'flex', gap: theme.spacing(2), marginTop: theme.spacing(4)}}>
        <Button onClick={handleAcceptAllClick} size="medium" variant="contained">
          Accept All
        </Button>
        <Button onClick={props.onCustomizeClick} size="medium" variant="outlined">
          Customize
        </Button>
        <Button onClick={handleRejectAllClick} size="medium" variant="outlined">
          Reject all
        </Button>
      </Box>
    </Container>
  );
});

export default ConsentDialog;
