import {generatePath} from 'react-router';
import {BillingPageTab} from '../pages/BillingPage/BillingPage';
import {DomainInfoTabs} from '../pages/DomainInfoPage/DomainInfoViewer';
import {DomainsPageTab} from '../pages/DomainsPage/DomainsPage';
import {ProfilePageTab} from '../pages/ProfilePage/ProfilePage';
import {ServerInfoTab} from '../pages/ServerInfoPage/ServerInfoViewer';
import {Domain} from '../stores/Domain';
import {Instance} from '../stores/Instance';
import Paths from './Paths';
import browserHistory from './browserHistory';

export const navigateToCreateServer = () => {
  browserHistory.push(generatePath(Paths.CreateServer));
};

export const navigateToSetupServerPlan = (planId: string, replace = false) => {
  const path = generatePath(Paths.SetupServerPlan, {
    planId,
  });

  if (replace) {
    browserHistory.replace(path);
  } else {
    browserHistory.push(path);
  }
};

export const navigateToServerInfo = (instance: Instance) => {
  browserHistory.push(
    generatePath(Paths.ServerInfo, {
      instanceId: instance.id ? `${instance.id}` : '',
      tabType: ServerInfoTab.OVERVIEW,
    }),
  );
};

export const navigateToDomains = (tab?: DomainsPageTab) => {
  browserHistory.push(
    generatePath(Paths.Domains, {
      tabType: tab || DomainsPageTab.Search,
    }),
  );
};

export const navigateToBilling = (tab?: BillingPageTab) => {
  browserHistory.push(
    generatePath(Paths.Billing, {
      tabType: tab || BillingPageTab.BILLING_DETAILS,
    }),
  );
};

export const navigateToDomainInfo = (domain: Domain, infoTabType = DomainInfoTabs.Overview) => {
  browserHistory.push(
    generatePath(Paths.DomainInfo, {
      domainId: domain.domainId ? `${domain.domainId}` : '',
      tabType: DomainsPageTab.MyDomains,
      infoTabType,
    }),
  );
};

export const navigateToProfile = (tab?: ProfilePageTab) => {
  browserHistory.push(
    generatePath(Paths.Profile, {
      tabType: tab || ProfilePageTab.Account,
    }),
  );
};
