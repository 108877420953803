import React from 'react';

import {useOrigin100vh} from '../../hooks/useOrigin100vh';
import {useStore} from '../../stores/AppStore';
import {getStyleProperty} from '../../utils/styleProperty';

export function use100vh() {
  const originHeight = useOrigin100vh();
  const {appVersionUpdater, utils} = useStore();

  const [height, setHeight] = React.useState<number | null>(null);
  const [systemAlertHeight, setSystemAlertHeight] = React.useState<number>(0);

  const updateAppBarHeight = parseInt(getStyleProperty('--update-app-top-bar-height'), 10);

  const updateSystemAlertHeight = React.useCallback(() => {
    const bar = document.getElementById('system-alert-bar');
    if (bar) {
      setSystemAlertHeight(bar.offsetHeight);
    } else {
      setSystemAlertHeight(0);
    }
  }, []);

  React.useEffect(() => {
    updateSystemAlertHeight();

    window.addEventListener('resize', updateSystemAlertHeight);
    return () => {
      window.removeEventListener('resize', updateSystemAlertHeight);
    };
  }, [updateSystemAlertHeight]);

  React.useEffect(() => {
    if (originHeight) {
      let h = originHeight;

      if (appVersionUpdater.hasNewVersion) {
        h -= updateAppBarHeight;
      }

      if (utils.hasAlertMessage) {
        h -= systemAlertHeight;
      }

      setHeight(h);
    }
  }, [originHeight, updateAppBarHeight, systemAlertHeight, utils.hasAlertMessage, appVersionUpdater.hasNewVersion]);

  return height || originHeight;
}

export default use100vh;
