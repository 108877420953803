import {LoadingButton} from '@mui/lab';
import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiChangeDomainContactError, getApiDomainContactError} from '../../api/getApiError';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import DomainContacts, {IDomainContactsRef} from '../../components/DomainContactsViewer/DomainContacts';
import Typography from '../../components/UI/Typography';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import {Domain} from '../../stores/Domain';
import DomainContact from '../../stores/Domain/DomainContact';

interface IProps {
  domain: Domain;
}

export const DomainContactInfo: React.FC<IProps> = observer((props) => {
  const {notification} = useStore();
  const {t} = useTranslation();
  const theme = useTheme();
  const domainContactsRef = React.useRef<IDomainContactsRef>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isEditableMode, setIsEditableMode] = React.useState<boolean>(false);
  const [showButtons, setShowButtons] = React.useState<boolean>(true);

  const handleEditableModeOn = () => {
    setIsEditableMode(true);
  };

  const handleEditableModeOff = () => {
    setIsEditableMode(false);
    domainContactsRef.current?.reset();
  };

  const handleToggleNewContactForm = (showNewContactForm: boolean) => {
    setShowButtons(!showNewContactForm);
  };

  const updateDomainContact = async (contactId: number) => {
    setLoading(true);

    const {res, error} = await props.domain.updateContact(contactId);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {contactErrors, errors} = res;

      if (contactErrors?.length || errors?.length) {
        errors?.forEach((error) => {
          notification.error(getApiChangeDomainContactError(error));
        });

        contactErrors?.forEach((error) => {
          notification.error(getApiDomainContactError(error));
        });
      }
    }

    setLoading(false);
  };

  const handleSubmitNewContactForm = async (contact: DomainContact) => {
    if (contact.id) {
      await updateDomainContact(contact.id);
    }

    handleEditableModeOff();
  };

  const handleSaveDomainContactInfo = async () => {
    const selectedContact = domainContactsRef.current?.getSelectedContact();

    if (selectedContact?.id) {
      await updateDomainContact(selectedContact.id);
    }

    handleEditableModeOff();
  };

  return (
    <PageContentLayout sx={{padding: theme.spacing(4)}}>
      <Typography variant="label2" sx={{marginBottom: theme.spacing(2)}}>
        {t('domain_contact_info_title')}
      </Typography>

      <Box sx={{maxWidth: 675}}>
        <DomainContacts
          ref={domainContactsRef}
          existingContact={props.domain.contact}
          isEditableMode={isEditableMode}
          onToggleContactForm={handleToggleNewContactForm}
          onSubmitNewContactForm={handleSubmitNewContactForm}
        />
      </Box>

      {showButtons ? (
        <Box sx={{marginTop: theme.spacing(4)}}>
          {isEditableMode ? (
            <LoadingButton loading={loading} variant="contained" size="large" onClick={handleSaveDomainContactInfo}>
              {t('action_button_save')}
            </LoadingButton>
          ) : (
            <Button variant="contained" size="large" onClick={handleEditableModeOn}>
              {t('domain_contact_info_edit_button')}
              <EditIcon style={{marginLeft: theme.spacing(1), fill: 'currentcolor'}} />
            </Button>
          )}
          {isEditableMode ? (
            <Button sx={{marginLeft: theme.spacing(2)}} variant="outlined" size="large" onClick={handleEditableModeOff}>
              {t('action_button_cancel')}
            </Button>
          ) : null}
        </Box>
      ) : null}
    </PageContentLayout>
  );
});

export default DomainContactInfo;
