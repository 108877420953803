import {alpha, Box, Button, styled, Typography, useTheme} from '@mui/material';
import React from 'react';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import CookieGroupList from './CookieGroupList';
import cookieCategories, {ICookieCategory, necessaryCategories} from './cookieCategories';
import {acceptCookieSelection, acceptNecessaryCookies, getConsentSelection} from './cookiesStateManager';

interface CustomizeConsentDialogProps {
  onBackClick?: () => void;
  onSaveClick?: () => void;
  onAcceptAllClick?: () => void;
  onRejectNonEssentialClick?: () => void;
  onCloseClick?: () => void;
}

const Container = styled(Box)(({theme}) => ({
  position: 'fixed',
  bottom: '10px',
  left: '10px',

  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.backgroundPrimary,
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  padding: theme.spacing(4),

  zIndex: 99999,
  maxWidth: 768,
  maxHeight: '90vh',
  boxShadow: `${alpha('#000', 0.2)} 2px 2px 18px 0`,

  lineHeight: 1,
}));

export function CustomizeConsentDialog(props: CustomizeConsentDialogProps) {
  const theme = useTheme();
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    getConsentSelection().categories || necessaryCategories,
  );

  const handleSelectionToggle = (cookieGroup: ICookieCategory) => {
    if (selectedCategories.includes(cookieGroup.id)) {
      setSelectedCategories(selectedCategories.filter((id) => id !== cookieGroup.id));
    } else {
      setSelectedCategories([...selectedCategories, cookieGroup.id]);
    }
  };

  const handleSavePreferences = () => {
    acceptCookieSelection(selectedCategories);
    props.onSaveClick?.();
  };

  const handleAcceptAllClick = () => {
    acceptCookieSelection(cookieCategories.map((c) => c.id));
    props.onAcceptAllClick?.();
  };

  const handleRejectNonEssentialClick = () => {
    acceptNecessaryCookies();
    props.onRejectNonEssentialClick?.();
  };

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Button onClick={props.onBackClick} size="small" variant="outlined" sx={{paddingRight: theme.spacing(2)}}>
          <ArrowLeft style={{fill: theme.palette.body.primary, marginLeft: theme.spacing(0)}} />
          Back
        </Button>
        <Button
          sx={{marginLeft: 'auto', minWidth: 34}}
          onClick={props.onCloseClick}
          size="small"
          variant="outlined"
          name="close-button"
          aria-label="Close dialog"
        >
          <CloseIcon style={{fill: theme.palette.body.primary}} />
        </Button>
      </Box>

      <Typography variant="subtitle1" gutterBottom sx={{marginTop: theme.spacing(4)}}>
        Customize Your Cookie Settings
      </Typography>

      <Box sx={{width: '100%'}} className="custom-scroll-y">
        <CookieGroupList selected={selectedCategories} onChange={handleSelectionToggle} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            backgroundColor: theme.palette.backgroundSecondary,
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: '8px',
            padding: theme.spacing(3),
            marginTop: theme.spacing(2),
          }}
        >
          <Typography variant="body3" sx={{color: theme.palette.body.tertiary}}>
            Your consent applies to the following domains:
          </Typography>
          <Typography variant="body2">{window.location.host}</Typography>
        </Box>
      </Box>

      <Box sx={{display: 'flex', marginTop: theme.spacing(4), gap: theme.spacing(2)}}>
        <Button onClick={handleSavePreferences} size="medium" variant="contained" color="primary">
          Save Preferences
        </Button>
        <Button onClick={handleAcceptAllClick} size="medium" variant="contained" color="secondary">
          Accept All
        </Button>
        <Button onClick={handleRejectNonEssentialClick} size="medium" variant="outlined">
          Reject Non-Essential
        </Button>
      </Box>
    </Container>
  );
}

export default CustomizeConsentDialog;
