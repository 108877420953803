import {action, makeObservable, observable, runInAction} from 'mobx';
import {AppStore} from '../AppStore';
import Plan from './Plan';

export class PlanView {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable activePlanId: string | null = null;
  @observable isPlanIdFromLandingRedirect: boolean = false;
  @observable activePlan: Plan | null = null;

  @observable isInit = false;

  @action setActivePlanId = (planId: string, isPlanIdFromLandingRedirect = false) => {
    this.activePlanId = planId;
    this.isPlanIdFromLandingRedirect = isPlanIdFromLandingRedirect;

    if (this.isInit) {
      this.loadActivePlan_();
    }
  };

  init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadActivePlan_();

    runInAction(() => {
      this.isInit = true;
    });
  };

  @action clearActivePlan = () => {
    this.activePlanId = null;

    if (this.activePlan) {
      this.activePlan.resetSetupPlanState();
    }

    this.activePlan = null;
  };

  private loadActivePlan_ = async () => {
    if (!this.activePlanId) {
      runInAction(() => {
        this.activePlan = null;
      });
      return;
    }

    const foundPlan = await this.app.plansStore.getPlanById(this.activePlanId);

    runInAction(() => {
      foundPlan?.init();
      this.activePlan = foundPlan || null;
    });
  };

  @action reset = () => {
    this.activePlan = null;
    this.activePlanId = null;
  };
}
