import {Button, Typography, useTheme} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as FavoriteStrokeIcon} from '../../../assets/icons/favorite-stroke.svg';
import {ReactComponent as FavoriteIcon} from '../../../assets/icons/favorite.svg';
import {useStore} from '../../../stores/AppStore';

const EditableSeverName: React.FC = observer(() => {
  const theme = useTheme();
  const {instanceView, notification} = useStore();
  const {activeInstance} = instanceView;
  const editableRef = React.useRef<HTMLDivElement>(null);

  const {t} = useTranslation();
  const [editingName, setEditingName] = React.useState<boolean>(false);

  const handleStartEditName = () => {
    setEditingName(true);
    editableRef.current?.focus();
  };

  const handleSaveName = async () => {
    setEditingName(false);

    const newServerName = editableRef?.current?.innerText;

    if (newServerName === activeInstance?.name) {
      return;
    }

    if (editableRef.current && activeInstance) {
      const {error, res} = await activeInstance.updateRequest({name: newServerName});

      if (!error && !res?.errors?.length) {
        setEditingName(false);

        notification.success(t('server_info_edit_name_success_message'));
      } else {
        setEditingName(false);

        if (activeInstance.name) {
          editableRef.current.innerText = activeInstance.name;
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleSaveName();
    }
  };

  const handleToggleAddToFavorite = async () => {
    if (!activeInstance) {
      return;
    }

    await activeInstance.toggleAddToFavorites();
  };

  return (
    <div className={cn('editable-server-name', {editing: editingName})}>
      <div>
        <Typography variant="body3" color="body.tertiary">
          {t('server_info_server_name_label')}
        </Typography>
        <div className="editable-server-name__inner">
          <div className="editable-server-name__editable-name">
            <Typography
              ref={editableRef}
              onKeyDown={handleKeyDown}
              variant="h4"
              contentEditable={editingName}
              suppressContentEditableWarning
            >
              {activeInstance?.name}
            </Typography>
          </div>
          <Button
            className="editable-server-name__button editable-server-name__button--edit"
            variant="outlined"
            size="small"
            onClick={editingName ? handleSaveName : handleStartEditName}
          >
            {editingName ? <CheckIcon /> : <EditIcon />}
          </Button>
        </div>
      </div>

      <Button
        className="editable-server-name__button"
        variant="outlined"
        size="small"
        sx={{minWidth: 0}}
        onClick={handleToggleAddToFavorite}
      >
        {activeInstance?.isImportant ? (
          <FavoriteIcon style={{fill: theme.palette.warning.primary}} />
        ) : (
          <FavoriteStrokeIcon style={{fill: theme.palette.body.primary}} />
        )}
      </Button>
    </div>
  );
});

export default EditableSeverName;
