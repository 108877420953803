import React from 'react';
import Div100vh from '../../components/Div100vh';
import Sidebar from '../../pages/Sidebar/Sidebar';
import MetaLayout from '../MetaLayout';
import AuthorizedLayoutWrapper from './AuthorizedLayoutWrapper';

export const AuthorizedLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <MetaLayout>
      <AuthorizedLayoutWrapper>
        <Div100vh className="authorized-layout">
          <Sidebar />
          {children}
        </Div100vh>
      </AuthorizedLayoutWrapper>
    </MetaLayout>
  );
};

export default AuthorizedLayout;
