import {action, makeObservable, observable} from 'mobx';
import browserStorage from '../../utils/browserStorage';

enum UITheme {
  LIGHT = 'light',
  DARK = 'dark',
}

const THEME_STORAGE_KEY = 'theme';

export class UIThemeStore {
  constructor() {
    makeObservable(this);
  }

  @observable lightThemeChosen = true;

  @action private setCurrentTheme_ = (theme: UITheme) => {
    const light = theme !== UITheme.DARK;
    if (this.lightThemeChosen === light) {
      return;
    }

    this.lightThemeChosen = light;
    try {
      browserStorage.setItem(THEME_STORAGE_KEY, theme !== UITheme.DARK ? UITheme.LIGHT : UITheme.DARK);
    } catch (e) {
      console.warn(e);
    }
  };

  public init = () => {
    try {
      const theme = browserStorage.getItem(THEME_STORAGE_KEY) !== 'dark' ? UITheme.LIGHT : UITheme.DARK;
      this.setCurrentTheme_(theme);
    } catch (e) {
      console.warn(e);
    }
  };

  public toggleCurrentTheme = () => {
    this.setCurrentTheme_(!this.lightThemeChosen ? UITheme.LIGHT : UITheme.DARK);
  };

  public switchOnLightTheme = () => {
    if (!this.lightThemeChosen) {
      this.setCurrentTheme_(UITheme.LIGHT);
    }
  };
}

export default UIThemeStore;
