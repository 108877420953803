import {Box, Button, styled, Tab, Tabs, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowLeftIcon} from '../../assets/icons/arrow-left.svg';
import {ProfilePageTab} from './ProfilePage';

// const Container = styled(Box)(({theme}) => ({
//   backgroundColor: theme.palette.backgroundPrimary,
//   padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
//   paddingBottom: 0,
//   borderBottom: `1px solid ${theme.palette.border.primary}`,
// }));

const Delimiter = styled(Box)(({theme}) => ({
  color: theme.palette.body.placeholder,
  margin: `0 ${theme.spacing(1)}`,
}));

interface IProps {
  currentTab: ProfilePageTab;
  onChange?(tab: ProfilePageTab);
  onClickBackButton?(): void;
}

export const ProfilePageHead: React.FC<IProps> = ({currentTab, onChange, onClickBackButton}) => {
  const {t} = useTranslation();

  const isChangeEmailOrPassword = [ProfilePageTab.ChangeEmail, ProfilePageTab.ChangePassword].includes(currentTab);

  const tabTitles = {
    [ProfilePageTab.Account]: t('profile_account_tab'),
    [ProfilePageTab.ChangeEmail]: t('profile_change_email_tab'),
    [ProfilePageTab.ChangePassword]: t('profile_change_password_tab'),
  };

  const displayTabsData = Object.entries({
    [ProfilePageTab.Account]: t('profile_account_tab'),
    [ProfilePageTab.ContactInformation]: t('profile_contact_information_tab'),
  });

  const handleChange = (_: React.SyntheticEvent, tab: ProfilePageTab) => {
    tab && onChange?.(tab);
  };

  return (
    <div className="profile-page-head">
      {isChangeEmailOrPassword ? (
        <div className="profile-page-head__body">
          <div className="profile-page-head__back">
            <Button
              className="profile-page-head__back-btn"
              variant="outlined"
              size="large"
              onClick={onClickBackButton}
              sx={(theme) => ({padding: theme.spacing(1), minWidth: 0})}
            >
              <ArrowLeftIcon width={24} height={24} />
            </Button>
          </div>
          <div className="profile-page-head__title-wrap">
            <Typography variant="body2" color="body.tertiary">
              {t('profile_title')}
              <Delimiter component="span">/</Delimiter>
              {tabTitles[ProfilePageTab.Account]}
            </Typography>
            <div className="profile-page-head__title-bottom">
              <Typography variant="h4" component="span">
                {tabTitles[currentTab]}
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <Typography variant="h4" gutterBottom>
          {t('profile_title')}
        </Typography>
      )}

      <Tabs className="profile-page-head__tabs lineView" value={currentTab} onChange={handleChange}>
        {displayTabsData.map(([tab, title]) => (
          <Tab
            className="lineView"
            key={tab}
            label={<Typography variant="subtitle1">{title}</Typography>}
            value={tab}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default ProfilePageHead;
