import {action, makeObservable, observable} from 'mobx';
import {matchPath} from 'react-router';
import Paths from '../../routes/Paths';
import browserHistory from '../../routes/browserHistory';
import {navigateToServerInfo} from '../../routes/navigateTo';
import logger from '../../utils/logger';
import {AppStore} from '../AppStore';
import Instance from './Instance';

export class InstanceView {
  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable activeInstance: Instance | null = null;

  @observable isInit: boolean = false;

  @action private setActiveInstance_ = (instance: Instance | null) => {
    this.activeInstance = instance;
  };

  @action init = () => {
    if (this.isInit) {
      return;
    }

    this.findInstanceByParams_();

    this.isInit = true;
  };

  private findInstanceByParams_ = () => {
    const match = matchPath({path: Paths.ServerInfo}, window.location.pathname);

    if (!match) {
      return;
    }

    const instanceId = Number(match?.params.instanceId);

    if (isNaN(instanceId)) {
      browserHistory.push(Paths.Servers);
      return;
    }

    const foundInstance = this.app.instancesStore.findInstanceById(instanceId);

    if (!foundInstance) {
      logger.error(`---> InstanceView: Instance by ID [${instanceId}] not found.`);
      browserHistory.push(Paths.Servers);
      return;
    }

    this.setActiveInstance_(foundInstance);
  };

  openInstanceInfoPage = (instance: Instance) => {
    this.setActiveInstance_(instance);
    navigateToServerInfo(instance);
  };

  deleteActiveInstance = async () => {
    await this.activeInstance?.delete();
    const matchServerInfo = matchPath(Paths.ServerInfo, browserHistory.location.pathname);

    if (matchServerInfo) {
      browserHistory.push(Paths.Servers);
    }

    this.clear();
  };

  @action clear = () => {
    this.activeInstance = null;
  };

  @action reset = () => {
    this.clear();

    this.activeInstance = null;
  };
}
