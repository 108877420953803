import {LoadingButton} from '@mui/lab';
import {Button, Typography} from '@mui/material';
import validator from 'email-validator';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router';
import * as Yup from 'yup';
import {getApiAuthEnterPasswordError} from '../../api/getApiError';
import {ca2auth} from '../../api/proto';
import PasswordInput from '../../components/UI/PasswordInput';
import {TextField} from '../../components/UI/TextField';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

type LocationState = {
  email?: string;
};

interface IProps {
  onSubmit?(): void;
}

const EnterPasswordForm: React.FC<IProps> = observer((props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const {authStore, notification} = useStore();
  const {t} = useTranslation();

  const [formState, setFormState] = React.useState<{loading: boolean; apiErrorKey: ca2auth.EnterPasswordError | null}>({
    loading: false,
    apiErrorKey: null,
  });

  const formik = useFormik({
    initialValues: {
      email: state?.email || '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(t('auth_email_verification_form_email_required'))
        .test(
          'is_email_or_backdoor',
          t('auth_email_verification_form_email_incorrect'),
          (value) => validator.validate(value) || /==$/.test(value),
        ),
      password: Yup.string().required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setFormState({loading: true, apiErrorKey: null});

      const {error, res} = await authStore.enterPassword({email: values.email, password: values.password});

      if (error) {
        notification.error(error.message);
      }

      if (res) {
        const {errors, successStep} = res;

        if (errors?.length) {
          setFormState({loading: false, apiErrorKey: errors[0]});
        }

        if (successStep?.token) {
          setFormState({loading: false, apiErrorKey: null});
          props.onSubmit?.();
        }
      }
    },
    onReset: () => {
      setFormState({loading: false, apiErrorKey: null});
    },
  });

  const handleForgotPassword = () => {
    navigate(Paths.ForgotPassword, {state: {email: formik.values.email || ''}});
  };

  const {loading, apiErrorKey} = formState;

  const isEmailApiError = apiErrorKey === ca2auth.EnterPasswordError.EPE_INVALID_EMAIL_ERROR;
  const isPasswordApiError = apiErrorKey === ca2auth.EnterPasswordError.EPE_INVALID_PASSWORD_ERROR;

  const apiErrorMessage = apiErrorKey ? getApiAuthEnterPasswordError(apiErrorKey) : '';

  return (
    <form className="auth-page__form" onSubmit={formik.handleSubmit}>
      <Typography variant="h1" gutterBottom>
        {t('auth_enter_password_form_title')}
      </Typography>
      <Typography variant="h3" sx={(theme) => ({marginBottom: theme.spacing(6), color: theme.palette.body.tertiary})}>
        {t('auth_enter_password_form_subtitle')}
      </Typography>

      <TextField
        fullWidth
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        placeholder={t('auth_email_verification_form_email_placeholder')}
        label={t('auth_email_verification_form_email_label')}
        error={!!formik.errors.email || isEmailApiError}
        helperText={formik.errors.email || (isEmailApiError ? apiErrorMessage : '')}
        autoFocus={!state?.email}
        autoComplete="email"
      />

      <PasswordInput
        fullWidth
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        placeholder={t('auth_personal_info_form_password_placeholder')}
        label={t('auth_personal_info_form_password_label')}
        error={!!formik.errors.password || isPasswordApiError}
        helperText={formik.errors.password || (isPasswordApiError ? apiErrorMessage : '')}
        autoFocus={!!state?.email}
        inputProps={{
          autoComplete: 'current-password',
        }}
        rightAdornment={
          <Button variant="outlined" size="large" sx={{marginLeft: 'auto'}} onClick={handleForgotPassword}>
            {t('auth_enter_password_forgot_password_button')}
          </Button>
        }
      />

      <LoadingButton fullWidth variant="contained" type="submit" size="large" loading={loading}>
        {t('auth_enter_password_form_submit')}
      </LoadingButton>
    </form>
  );
});

export default EnterPasswordForm;
