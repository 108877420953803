import {observer} from 'mobx-react';
import React from 'react';
import {Navigate} from 'react-router';
import Div100vh from '../../components/Div100vh';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import ServerInfoViewer from './ServerInfoViewer';

export const ServerInfoPage: React.FC = observer(() => {
  const {instanceView} = useStore();

  if (!instanceView.activeInstance) {
    return <Navigate to={Paths.Servers} replace />;
  }

  return (
    <Div100vh className="page page--server-info">
      <ServerInfoViewer instance={instanceView.activeInstance} />
    </Div100vh>
  );
});

export default ServerInfoPage;
