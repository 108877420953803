import Cookies from 'js-cookie';
import {v4 as uuidv4} from 'uuid';
import cookieCategories, {
  ICookieCategory,
  necessaryCategories,
  performanceCookieCategory,
  targetingCookieCategory,
} from './cookieCategories';

export const COOKIE_SCRIPT_CONSENT_KEY = 'CookieScriptConsent';

interface CookieSelection {
  action?: 'accept' | 'reject';
  consenttime?: number;
  categories?: string[];
  version?: string;
  consentId?: string;
  shownFirstTimeAt?: number;
}

function stringify(obj: any) {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
  return '';
}

export function getConsentSelection(): CookieSelection {
  const selectionStr = Cookies.get(COOKIE_SCRIPT_CONSENT_KEY);
  try {
    return selectionStr ? JSON.parse(selectionStr) : {};
  } catch (e) {
    console.error(e);
  }
  return {};
}

function saveSelection(selection: CookieSelection) {
  const consent = {
    consentId: uuidv4(),
    version: process.env.REACT_APP_VERSION,
    consenttime: new Date().getTime(),
    ...selection,
  };
  const consentStr = stringify(consent);

  Cookies.set(COOKIE_SCRIPT_CONSENT_KEY, consentStr, {
    expires: 365,
    domain: `.${window.location.host.split('.').slice(-2).join('.')}`,
  });
}

export function acceptCookieSelection(categories: string[]) {
  const state: CookieSelection = {
    action: 'accept',
    categories: categories,
  };

  saveSelection(state);
}

export function acceptAllCookies() {
  const state: CookieSelection = {
    action: 'accept',
    categories: cookieCategories.map((c) => c.id),
  };

  saveSelection(state);
}

export function acceptNecessaryCookies() {
  const state: CookieSelection = {
    action: 'accept',
    categories: necessaryCategories,
  };

  saveSelection(state);
}

export function rejectCookieSelection() {
  const state: CookieSelection = {
    action: 'reject',
    categories: [],
  };

  saveSelection(state);
}

export function isNecessaryCookie(cookieGroup: ICookieCategory): boolean {
  return necessaryCategories.some((category) => category === cookieGroup.id);
}

export function isNotAcceptedOrRejectedConsent(): boolean {
  const consent = getConsentSelection();
  return consent.action !== 'accept' && consent.action !== 'reject';
}

export function isAcceptedOrRejectedConsent(): boolean {
  const consent = getConsentSelection();
  return consent.action === 'accept' || consent.action === 'reject';
}

export function isAcceptedConsent(): boolean {
  const consent = getConsentSelection();
  return consent.action === 'accept';
}

export function isAcceptedPerformance(): boolean {
  const consent = getConsentSelection();
  return !!consent.categories?.includes(performanceCookieCategory.id);
}

export function isAcceptedTargeting(): boolean {
  const consent = getConsentSelection();
  return !!consent.categories?.includes(targetingCookieCategory.id);
}

export function hasConsentShown(): boolean {
  const consent = getConsentSelection();
  return !!consent.shownFirstTimeAt;
}

export function markConsentShown() {
  if (hasConsentShown()) {
    return;
  }

  const consent = getConsentSelection();

  const state: CookieSelection = {
    ...consent,
    shownFirstTimeAt: new Date().getTime(),
  };

  saveSelection(state);
}
