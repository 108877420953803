import {SVGProps} from 'react';
import {ReactComponent as BchIcon} from '../assets/image-icons/bch.svg';
import {ReactComponent as BtcIcon} from '../assets/image-icons/btc.svg';
import {ReactComponent as DogeIcon} from '../assets/image-icons/doge.svg';
import {ReactComponent as EthIcon} from '../assets/image-icons/eth.svg';
import {ReactComponent as LtcIcon} from '../assets/image-icons/ltc.svg';
import {ReactComponent as UsdtErcIcon} from '../assets/image-icons/usdt-erc.svg';
import {ReactComponent as UsdtTrcIcon} from '../assets/image-icons/usdt-trc.svg';
import {ReactComponent as UsdcIcon} from '../assets/image-icons/usdc.svg';

const IconsDicrt: Record<
  string,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  bch: BchIcon,
  btc: BtcIcon,
  doge: DogeIcon,
  eth: EthIcon,
  ltc: LtcIcon,
  usdte: UsdtErcIcon,
  usdtt: UsdtTrcIcon,
  usdc: UsdcIcon,
  usdcs: UsdcIcon,
};

export const CurrencyIcon: React.FC<
  SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  } & {code: string}
> = ({code, ...props}) => {
  const Component = IconsDicrt[code.toLowerCase()];
  return Component ? <Component {...props} /> : null;
};

export default CurrencyIcon;
