export enum Paths {
  Root = '/',
  Default = '/servers',

  Blocked = '/blocked',
  Logout = '/logout',
  Authorization = '/authorization',
  ForgotPassword = '/forgotPassword',

  Profile = '/profile/:tabType?',
  CreateServer = '/createServer',
  SetupServerPlan = '/createServer/:planId',
  ResizeServer = '/resizeServer',
  Servers = '/servers',
  ServerInfo = '/servers/:instanceId/:tabType',
  Domains = '/domains/:tabType?',
  DomainInfo = '/domains/:tabType?/:domainId/:infoTabType',
  SshKeys = '/sshKeys',
  Team = '/team',

  Billing = '/billing/:tabType?',
  Notifications = '/notifications',
}

export default Paths;

export type SetupServerPlanRouteParams = {
  planId?: string;
};

export type InstanceRouteParams = {
  instanceId?: string;
  tabType?: string;
};

export type DomainsRouteParams = {
  tabType?: string;
};

export type BillingRouteParams = {
  tabType?: string;
};

export type DomainRouteParams = {
  domainId?: string;
  infoTabType?: string;
};

export type ProfileRouteParams = {
  tabType?: string;
};
