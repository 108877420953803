import {makeObservable} from 'mobx';

import SidebarStore from './SiedebarStore';
import {UIThemeStore} from './UIThemeStore';

export class LayoutStore {
  uiTheme: UIThemeStore = new UIThemeStore();
  sidebar: SidebarStore = new SidebarStore();

  constructor() {
    makeObservable(this);

    this.init_();
  }

  private init_ = () => {
    this.uiTheme.init();
    this.sidebar.init();
  };
}

export default LayoutStore;
