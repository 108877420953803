import {Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from '@mui/material';
import {FormikErrors} from 'formik';
import React from 'react';
import {ca2types} from '../../../api/proto';
import {ReactComponent as PlusIcon} from '../../../assets/icons/plus.svg';
import Typography from '../../../components/UI/Typography';
import {MAX_DOMAIN_NS_RECORDS_AMOUNT} from '../../../stores/Domain';
import {DNSRecordKeyType, IDNSRecord, RecordType, updateDnsRecord} from '../../../stores/Domain/DnsRecordsStore';
import DomainRecordsTableRow, {IDomainRecordsTableRowRef} from './DomainRecordsTableRow';
import DomainRecordTableForm, {IDomainRecordTableFormRef} from './DomainRecordTableForm';
import {DomainRecordColumn} from './types';

export const DomainRecordTableCell = styled(TableCell)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  '&.MuiTableCell-head': {
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  '&.MuiTableCell-body': {
    height: '59px',
  },
}));

export interface IDomainRecordsTableRef {
  showRecordForm(): void;
  hideRecordForm(): void;
  resetAllForms(): void;
  resetForm(recordId: string): void;
  setFormErrors(recordId: string, errors: FormikErrors<RecordType>): void;
  setNewRecordFormErrors(errors: FormikErrors<RecordType>): void;
}

interface IProps {
  recordType: ca2types.DNSRecordType | 'NS';
  recordKey: DNSRecordKeyType;
  columns: DomainRecordColumn[];
  dnsRecords: IDNSRecord[];
  addRecordButtonText: string;
  onDeleteRecord?(record: IDNSRecord): Promise<void>;
  onUpdateRecord?(updated: IDNSRecord): Promise<void>;
  onAddNewRecord?(record: IDNSRecord): Promise<void>;
  readOnly?: boolean;
  isInitialNsRecords?: boolean;
  hideRowFormSubmitButton?: boolean;
}

export const DomainRecordsTable = React.forwardRef(function DomainRecordsTable(
  props: IProps,
  ref: React.Ref<IDomainRecordsTableRef>,
) {
  const theme = useTheme();
  const [showForm, setShowForm] = React.useState<boolean>(false);

  const domainRecordFormRef = React.useRef<IDomainRecordTableFormRef>(null);

  const domainRecordsTableRowRefs = React.useRef<Record<string, IDomainRecordsTableRowRef | null>>({});

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCancelForm = () => {
    setShowForm(false);
    domainRecordFormRef.current?.reset();
  };

  React.useImperativeHandle(ref, () => ({
    showRecordForm: () => {
      setShowForm(true);
    },
    hideRecordForm: handleCancelForm,
    resetForm: (recordId: string) => {
      domainRecordsTableRowRefs.current[recordId]?.resetForm();
    },
    setFormErrors: (recordId: string, errors: FormikErrors<RecordType>) => {
      domainRecordsTableRowRefs.current[recordId]?.setFormErrors(errors);
    },
    setNewRecordFormErrors: (errors: FormikErrors<RecordType>) => {
      domainRecordFormRef.current?.setErrors(errors);
    },
    resetAllForms: () => {
      Object.values(domainRecordsTableRowRefs.current).forEach((ref) => ref?.resetForm());
      setShowForm(false);
    },
  }));

  const handleAddNewRecord = async (newRecord: RecordType) => {
    await props.onAddNewRecord?.(updateDnsRecord({type: props.recordType}, newRecord));
  };

  const canShowAddNewRecordButton = () => {
    if (props.readOnly) {
      return false;
    }

    if (props.recordType === 'NS' && props.dnsRecords.length >= MAX_DOMAIN_NS_RECORDS_AMOUNT) {
      return false;
    }

    return true;
  };

  return (
    <>
      <TableContainer>
        <Table sx={{borderCollapse: 'separate'}}>
          <TableHead>
            <TableRow>
              {props.columns.map((column) => (
                <DomainRecordTableCell
                  sx={{width: `calc(100% / ${props.columns.length || 1})`}}
                  key={String(column.name)}
                >
                  {column.label}
                </DomainRecordTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dnsRecords.map((dnsRecord, idx) => (
              <DomainRecordsTableRow
                recordKey={props.recordKey}
                ref={(el) => (domainRecordsTableRowRefs.current[dnsRecord.id?.toString() || ''] = el)}
                key={dnsRecord.id?.toString()}
                dnsRecord={dnsRecord}
                columns={props.columns}
                onDelete={props.onDeleteRecord}
                onSubmit={props.onUpdateRecord}
                canDelete={!props.readOnly && props.recordType === 'NS' ? idx >= 2 : true}
                canEdit={!props.readOnly}
                isInitialNsRecords={props.isInitialNsRecords}
                hideFormSubmitButton={props.hideRowFormSubmitButton}
              />
            ))}

            {showForm && !props.readOnly ? (
              <DomainRecordTableForm
                ref={domainRecordFormRef}
                columns={props.columns}
                onSubmit={handleAddNewRecord}
                onCancel={handleCancelForm}
                hideSubmitButton={props.hideRowFormSubmitButton}
              />
            ) : canShowAddNewRecordButton() ? (
              <TableRow sx={{height: 58}} onClick={handleShowForm}>
                <TableCell
                  sx={{
                    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
                    color: theme.palette.brand.primary,
                    backgroundColor: theme.palette.brand.alpha1,
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  colSpan={props.columns.length}
                >
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <PlusIcon style={{fill: 'currentcolor', marginRight: theme.spacing(1)}} />
                    <Typography>{props.addRecordButtonText}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default DomainRecordsTable;
