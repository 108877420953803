import cn from 'classnames';
import React from 'react';

import {Link, Typography, useTheme} from '@mui/material';
import {ICookieInfo} from './cookieCategories';

interface CookieTableRowProps {
  className?: string;
  cookie: ICookieInfo;
}

export const CookieTableRow: React.FC<CookieTableRowProps> = ({className, cookie}) => {
  const theme = useTheme();
  return (
    <div className={cn('cookie-table__row', className)}>
      <div className="cookie-table__cell cookie-table__cell--name">
        <Typography variant="body3">{cookie.name}</Typography>
      </div>
      <div className="cookie-table__cell body3-regular cookie-table__cell--provider">
        {cookie.providerLink ? (
          <Link href={cookie.providerLink} target="_blank" sx={{display: 'block'}}>
            <Typography
              variant="body3"
              sx={{color: theme.palette.brand.primary, textTransform: 'underline', marginX: theme.spacing(0)}}
            >
              {cookie.provider}
            </Typography>
          </Link>
        ) : (
          <Typography variant="body3" component="span" sx={{color: theme.palette.brand.primary}}>
            {cookie.provider}
          </Typography>
        )}
        <Typography variant="body3" sx={{color: theme.palette.brand.primary}}>
          {cookie.domain}
        </Typography>
      </div>
      <div className="cookie-table__cell cookie-table__cell--expiration">
        <Typography variant="body3">{cookie.expiration}</Typography>
      </div>
      <div className="cookie-table__cell cookie-table__cell--description">
        <Typography variant="body3">{cookie.description}</Typography>
      </div>
    </div>
  );
};

export default CookieTableRow;
