import {alpha, styled} from '@mui/material';
import {observer} from 'mobx-react';
import CookieDarkIcon from '../../assets/image-icons/cookie-dark.svg';
import CookieIcon from '../../assets/image-icons/cookie.svg';
import {useStore} from '../../stores/AppStore';

const Button = styled('button')(({theme}) => ({
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.backgroundPrimary,
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '12px',
  boxShadow: `${alpha('#000', 0.2)} 2px 2px 18px 0`,
  zIndex: 99999,
  lineHeight: 0,
  fontSize: 0,
  opacity: 1,
  cursor: 'pointer',
}));

interface CookieButtonProps {
  onClick: () => void;
}

export const CookieButton = observer((props: CookieButtonProps) => {
  const {lightThemeChosen} = useStore();
  return (
    <Button onClick={props.onClick} type="button" name="cookie-button" aria-label="cookie-manage">
      <img src={lightThemeChosen ? CookieIcon : CookieDarkIcon} alt="" width={28} height={28} />
    </Button>
  );
});

export default CookieButton;
