import {Box, FormControlLabel, FormControlLabelProps, styled} from '@mui/material';
import React from 'react';
import {ReactComponent as OptionIcon} from '../../../assets/icons/server-option.svg';

import {useTranslation} from 'react-i18next';
import {Switch} from '../../../components/UI/Switch';
import Addon from '../../../stores/ServerOptions/Addon';

export const SwitchBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  padding: theme.spacing(2),
  textWrap: 'nowrap',
  marginBottom: theme.spacing(4),
  '& .Mui-disabled svg': {
    fill: theme.palette.body.placeholder,
  },
  '& svg': {
    fill: theme.palette.body.primary,
    marginRight: theme.spacing(1),
  },
  '& .MuiFormControlLabel-root': {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    width: '100%',
  },
  '& .MuiSwitch-root': {
    marginLeft: 'auto',
  },
  '& .MuiTypography-root': {
    display: 'flex',
    alignItems: 'end',
    width: '100%',
  },
  '& .MuiStack-root': {
    width: 'auto !important',
  },
  '& .price': {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  addon: Addon;
  checked?: boolean;
  onChange: FormControlLabelProps['onChange'];
}

const AddonSwitchBox: React.FC<IProps> = ({addon, checked, onChange}) => {
  const {t} = useTranslation();

  return (
    <SwitchBox>
      <FormControlLabel
        control={<Switch sx={(theme) => ({marginLeft: theme.spacing(1)})} />}
        labelPlacement="start"
        onChange={onChange}
        checked={checked}
        label={
          <>
            <OptionIcon />
            {addon.title}
            <div className="price">
              {addon.monthlyRateFormatter.points
                ? t('create_server_setup_plan_addon_per_month_price_label', {
                    price: addon.monthlyRateFormatter.formatted,
                  })
                : t('create_server_setup_plan_addon_free_label')}
            </div>
          </>
        }
      />
    </SwitchBox>
  );
};

export default AddonSwitchBox;
