import Long from 'long';
import {action, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import Domain from './Domain';
import DomainsStore from './DomainsStore';

export class DomainContact {
  @observable id: number | null = null;
  @observable createdAt: Long | null = null;
  @observable firstName: string | null = null;
  @observable lastName: string | null = null;
  @observable emailAddress: string | null = null;
  @observable phoneNumber: string | null = null;
  @observable mailingAddress: string | null = null;
  @observable mailingCity: string | null = null;
  @observable mailingState: string | null = null;
  @observable mailingZip: string | null = null;
  @observable mailingCountryCode: string | null = null;
  @observable domainIds: Long[] = [];
  @observable shouldBeFixed: boolean = false;

  constructor(public raw: ca2types.IDomainContact, public domainsStore: DomainsStore) {
    this.update(raw);
  }

  get associatedDomains() {
    return this.domainIds.reduce<Domain[]>((acc, id) => {
      const domain = this.domainsStore.findById(id);
      if (domain) acc.push(domain);
      return acc;
    }, []);
  }

  @action update = (raw: ca2types.IDomainContact) => {
    Object.assign(this, raw);
  };
}

export default DomainContact;
