import Long from 'long';
import {computed, configure, makeObservable, observable, runInAction} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import React from 'react';
import Network, {NetworkEvent} from '../api/network';
import WsNetwork, {WsNetworkEvent} from '../api/wsNetwork';
import {authPageOpen} from '../routes/routes';
import AppVersionUpdater from './AppVersionUpdater';
import AuthStore from './AuthStore';
import BillingStore from './BillingStore';
import DomainsStore from './Domain';
import DomainsView from './Domain/DomainsView';
import InstancesStore from './Instance';
import {InstanceView} from './Instance/InstanceView';
import LayoutStore from './LayoutStore';
import ModalsStore from './ModalsStore';
import NotificationsStore from './NotificationsStore';
import PlansStore from './Plan';
import {PlanView} from './Plan/PlanView';
import ServerOptions from './ServerOptions';
import {SshKeysStore} from './SshKeysStore';
import UserStore from './UserStore';
import UtilsStore from './UtilsStore';

configure({
  enforceActions: 'observed',
  disableErrorBoundaries: true,
});

export class AppStore {
  version = process.env.REACT_APP_VERSION;

  appVersionUpdater: AppVersionUpdater = new AppVersionUpdater(this);
  layoutStore: LayoutStore = new LayoutStore();
  notification: NotificationsStore = new NotificationsStore();
  modals: ModalsStore = new ModalsStore();

  authStore: AuthStore = new AuthStore(this);
  api: Network = new Network(this);
  wsApi: WsNetwork = new WsNetwork(this);
  utils: UtilsStore = new UtilsStore(this);

  userStore: UserStore = new UserStore(this);
  serverOptions: ServerOptions = new ServerOptions(this);
  plansStore: PlansStore = new PlansStore(this);
  instancesStore: InstancesStore = new InstancesStore(this);
  sshKeysStore: SshKeysStore = new SshKeysStore(this);
  domainsStore: DomainsStore = new DomainsStore(this);

  billingStore: BillingStore = new BillingStore(this);

  planView: PlanView = new PlanView(this);
  domainsView: DomainsView = new DomainsView(this);
  instanceView: InstanceView = new InstanceView(this);

  @observable private isInit_: boolean = false;
  @observable private isAuthenticatedInit_: boolean = false;

  constructor() {
    makeObservable(this);
    this.init();

    this.api.on(NetworkEvent.INVALID_TOKEN, this.invalidTokenHandler_);
    this.wsApi.on(WsNetworkEvent.INVALID_TOKEN, this.invalidTokenHandler_);
  }

  @computed get isAppInit() {
    if (this.authStore.isLoggedIn && !authPageOpen()) {
      return this.isInit_ && this.isAuthenticatedInit_;
    }

    return this.isInit_;
  }

  @computed get lightThemeChosen(): boolean {
    return this.layoutStore.uiTheme.lightThemeChosen;
  }

  private invalidTokenHandler_ = () => {
    this.authStore.logout();
  };

  init = async () => {
    if (this.isInit_) {
      return;
    }

    this.authStore.init();
    await this.utils.init();
    await this.userStore.init();

    if (this.authStore.isLoggedIn) {
      await this.authenticatedInit();
    }

    runInAction(() => {
      this.isInit_ = true;
    });
  };

  authenticatedInit = async () => {
    await this.serverOptions.init();
    await this.plansStore.init();
    await this.planView.init();
    await this.domainsStore.init();
    await this.sshKeysStore.init();
    await this.billingStore.init();
    await this.instancesStore.init();
    this.instanceView.init();

    runInAction(() => {
      this.isAuthenticatedInit_ = true;
    });
  };

  reset = () => {
    this.authStore.reset();
    this.userStore.reset();
    this.serverOptions.reset();
    this.plansStore.reset();
    this.instancesStore.reset();
    this.instanceView.reset();
    this.sshKeysStore.reset();
    this.domainsStore.reset();
    this.planView.reset();
    this.domainsView.reset();
    this.billingStore.reset();

    runInAction(() => {
      this.isAuthenticatedInit_ = false;
    });
  };
}

const store = new AppStore();

window.appStore = store;
window.Long = Long;

export default store;

export function useStore(): AppStore {
  return React.useContext(MobXProviderContext).store;
}
