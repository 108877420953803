import {observer} from 'mobx-react';
import React from 'react';

import Div100vh from '../../components/Div100vh';
import AuthHead from '../../pages/Auth/AuthHeader';

export const BaseAuthLayout: React.FC<React.PropsWithChildren> = observer(({children}) => {
  return (
    <Div100vh className="auth-layout">
      <AuthHead />
      {children}
    </Div100vh>
  );
});

export default BaseAuthLayout;
