import {Box, Button, Grid, styled, useTheme} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowCircleUp} from '../../../assets/icons/arrow-circle-up.svg';
import {ReactComponent as RamIcon} from '../../../assets/icons/ram.svg';
import {ReactComponent as SpeedIcon} from '../../../assets/icons/speed.svg';
import {ReactComponent as StorageIcon} from '../../../assets/icons/storage.svg';
import CpuOrGpuLogo from '../../../components/CpuOrGpuLogo';
import Typography from '../../../components/UI/Typography';
import {Instance} from '../../../stores/Instance';
import OverviewItem from './OverviewItem';

const StyledArrowCircleUp = styled(ArrowCircleUp)(({theme}) => ({
  marginRight: theme.spacing(2),
  '#bg': {
    fill: `${theme.palette.brand.secondary} !important`,
  },
}));

interface IProps {
  instance: Instance;
  onClickResizeServer?(instance: Instance): void;
}

const Configuration: React.FC<IProps> = ({instance, onClickResizeServer}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const handleClickResizeServe = () => {
    onClickResizeServer?.(instance);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box
        sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: theme.spacing(3)}}
      >
        <Typography variant="label3">{t('server_info_overview_configuration_title')}</Typography>
      </Box>

      <Grid container spacing={2} alignSelf="baseline">
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <CpuOrGpuLogo modelName={instance.plan?.cpu?.model} />
                </div>
                <Typography variant="body2">{instance.plan?.cpu?.model}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <RamIcon />
                </div>
                <Typography variant="body2">{instance.plan?.ramSizeFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <StorageIcon />
                </div>

                <Typography variant="body2">{instance.plan?.storageSizeFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <SpeedIcon />
                </div>

                <Typography variant="body2">{instance.plan?.transferFormatted}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <OverviewItem
            value={
              <>
                <div className="border-icon">
                  <Typography variant="label3">OS</Typography>
                </div>

                <Typography variant="body2">{instance.imageDisplayName}</Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth size="large" onClick={handleClickResizeServe}>
            <StyledArrowCircleUp />
            {t('server_info_overview_configuration_upgrade_server_button')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Configuration;
