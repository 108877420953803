import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import PageContentLayout from '../../layouts/PageContentLayout';
import {useStore} from '../../stores/AppStore';
import DomainContact from '../../stores/Domain/DomainContact';
import {ModalType} from '../../stores/ModalsStore';
import SearchInput from '../UI/SearchInput';
import Typography from '../UI/Typography';
import DomainContactItem from './DomainContacts/DomainContactItem';
import EmptyContactsListTitle from './EmptyContactsListTitle';

export const DomainContactsViewer: React.FC = observer(() => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {
    modals,
    domainsStore: {contacts},
  } = useStore();

  const contactsList = contacts.list;

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [filteredContacts, setFilteredContacts] = React.useState<DomainContact[]>(contactsList);

  React.useEffect(() => {
    setFilteredContacts(contactsList);
  }, [contactsList]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredContacts(contactsList);
    } else {
      const filtered = contactsList.filter((contact) => {
        return (
          (contact.firstName && contact.firstName.toLowerCase().includes(value.toLowerCase())) ||
          (contact.lastName && contact.lastName.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingAddress && contact.mailingAddress.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingCity && contact.mailingCity.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingState && contact.mailingState.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingZip && contact.mailingZip.toLowerCase().includes(value.toLowerCase())) ||
          (contact.mailingCountryCode && contact.mailingCountryCode.toLowerCase().includes(value.toLowerCase())) ||
          (contact.emailAddress && contact.emailAddress.toLowerCase().includes(value.toLowerCase())) ||
          (contact.phoneNumber && contact.phoneNumber.toLowerCase().includes(value.toLowerCase())) ||
          (contact.domainIds && contact.domainIds.some((id) => id.toString().includes(value)))
        );
      });
      setFilteredContacts(filtered);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    setFilteredContacts(contactsList);
  };

  const handleAddNewContact = () => {
    modals.open(ModalType.EDIT_DOMAIN_CONTACT);
  };

  const handleContactDeleted = (contactId: number | null) => {
    setFilteredContacts(contactsList.filter((contact) => contact.id !== contactId));
  };

  if (!contacts.list.length) {
    return <EmptyContactsListTitle />;
  }

  return (
    <Box sx={{maxWidth: 815, height: '100%'}}>
      <PageContentLayout sx={{padding: theme.spacing(4), paddingTop: '0px'}}>
        <Box
          sx={{
            position: 'sticky',
            paddingTop: theme.spacing(4),
            top: 0,
            backgroundColor: theme.palette.backgroundPrimary,
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: theme.spacing(4),
            }}
          >
            <Typography variant="label1" gutterBottom>
              {t('component_domain_contacts_viewer_title')}
            </Typography>
            <Button
              size="large"
              endIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
              onClick={handleAddNewContact}
            >
              {t('component_domain_contacts_viewer_add_contact_button')}
            </Button>
          </Box>

          {contactsList.length > 1 ? (
            <SearchInput
              value={searchTerm}
              placeholder={t('component_domain_contacts_viewer_search_placeholder')}
              onChange={handleChange}
              onClear={handleClear}
              sx={{marginBottom: theme.spacing(2), backgroundColor: theme.palette.backgroundPrimary}}
            />
          ) : null}
        </Box>

        {filteredContacts.map((contact) => (
          <DomainContactItem
            key={contact.id}
            contact={contact}
            onContactDeleted={handleContactDeleted}
            showConnectedDomains
          />
        ))}
      </PageContentLayout>
    </Box>
  );
});

export default DomainContactsViewer;
