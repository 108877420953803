import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers, ca2types} from '../../api/proto';
import {ZERO_AMOUNT} from '../../constants';
import PriceFormatter from '../../utils/priceFormatter';
import PaymentOption from '../BillingStore/PaymentOption';
import ServerOptions from '../ServerOptions';
import Addon from '../ServerOptions/Addon';
import ImageOptions from '../ServerOptions/options/ImageOptions';
import BasePlan from './BasePlan';
import PlansStore from './PlansStore';

export class Plan extends BasePlan {
  constructor(public raw: ca2types.IServerPlan, private plansStore: PlansStore) {
    super(raw);
    makeObservable(this);
  }

  images: ImageOptions = new ImageOptions();

  @observable loading: boolean = false;

  @computed get serverOptions(): ServerOptions {
    return this.plansStore.app.serverOptions;
  }

  @computed get hasBalanceToPayFirstHours(): boolean {
    return this.firstHoursRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get hasBalanceToPayByMonth(): boolean {
    return this.monthlyRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get addons(): Addon[] {
    return this.serverOptions.addons.list.filter(({providerId}) => this.providerId && providerId === this.providerId);
  }

  @computed get benefits(): ca2types.IBenefit[] {
    return this.serverOptions.benefits.findByIds(this.benefitIds);
  }

  @computed get regions(): ca2types.IRegion[] {
    return this.serverOptions.regions.findByIds(this.regionIds);
  }

  @computed get datacenters(): ca2types.IDatacenter[] {
    return this.serverOptions.datacenters.list.filter(({id}) => id && this.datacenterIds.includes(id));
  }

  @computed get provider() {
    if (!this.providerId) {
      return null;
    }

    return this.serverOptions.providers.findById(this.providerId) || null;
  }

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  findRegionByDatacenterId = (datacenterId?: string | null): ca2types.IRegion | undefined => {
    if (!datacenterId) {
      return undefined;
    }

    return this.regions.find((region) => region.datacenterIds?.includes(datacenterId));
  };

  findDatacentersByRegionId = (regionId?: string | null): ca2types.IDatacenter[] => {
    const foundRegion = this.regions.find(({id}) => regionId && id === regionId);

    if (!foundRegion) {
      return this.datacenters;
    }

    return this.datacenters.filter(({id}) => id && foundRegion.datacenterIds?.includes(id));
  };

  @action resetSetupPlanState = () => {
    this.initImageFamily();

    this.monthlyTotalRateFormatter.reset();
    this.hourlyTotalRateFormatter.reset();
  };

  init = async () => {
    this.setLoading(true);

    await this.loadPlanAdditionalData();
    this.initImageFamily();

    this.setLoading(false);
  };

  @observable private isPlanAdditionalDataLoaded_ = false;

  @action loadPlanAdditionalData = async () => {
    if (this.isPlanAdditionalDataLoaded_) {
      return;
    }

    const {res} = await this.plansStore.request({
      servers: {
        imagesList: {
          planId: this.id,
        },
        paymentOptions: {
          planId: this.id,
        },
      },
    });

    if (res?.servers) {
      this.processLoadPlanAdditionalData_(res.servers);
    }
  };

  private processLoadPlanAdditionalData_ = ({imagesList, paymentOptions}: ca2servers.IResponse) => {
    if (imagesList?.items) {
      this.images.init(imagesList.items);
    }

    if (paymentOptions) {
      this.hoursLeftWithBalance = paymentOptions.hoursLeftWithGivenAmount || 0;
      this.paymentOptions = paymentOptions.items ? paymentOptions.items.map((raw) => new PaymentOption(raw)) : [];
      if (paymentOptions.firstPaymentWithAddons) {
        this.firstPaymentWithAddonsFormatter = new PriceFormatter(paymentOptions.firstPaymentWithAddons);
      }
      if (paymentOptions.maxAmount) {
        this.maxAmountFormatter = new PriceFormatter(paymentOptions.maxAmount);
      }
    }
  };

  // PLAN PAYMENT OPTIONS
  @observable paymentOptions: PaymentOption[] = [];
  @observable hoursLeftWithBalance: number = 0;
  @observable firstPaymentWithAddonsFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable maxAmountFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);

  // PLAN IMAGES
  @observable selectedImageFamilyName: string | null = null;
  @observable selectedImageId: string | null = null;

  @computed get imageFamilies(): Record<string, ca2types.IImage[]> {
    return this.images.getDictionaryByFamily(this.providerId, this.allowWindowsImages);
  }

  @computed get imageFamiliesList(): string[] {
    return Object.keys(this.imageFamilies).map((family) => family);
  }

  @computed get imagesList() {
    if (!this.selectedImageFamilyName) {
      return [];
    }

    const list = this.imageFamilies[this.selectedImageFamilyName] || [];

    if (!this.allowWindowsImages) {
      return list.filter((image) => !image.isWindows);
    }

    return list;
  }

  @computed get selectedImage() {
    return this.images.findImageById(this.selectedImageId);
  }

  @computed get isWindow() {
    return !!this.selectedImage?.isWindows;
  }

  @computed get passwordAllow() {
    return !this.selectedImage?.passwordDisabled;
  }

  @action initImageFamily() {
    const familyName = this.imageFamiliesList[0];
    this.changeImageFamilyName(familyName);
  }

  @action changeImageFamilyName = (family: string | null) => {
    const image = family ? this.imageFamilies[family][0] : null;

    if (image) {
      this.setSelectedImageId(image.id);

      if (image.isWindows) {
        this.addWindowsLicenseRate();
      } else {
        this.subtractWindowsLicenseRate();
      }
    }

    this.selectedImageFamilyName = family;
  };

  @action setSelectedImageId = (imageId?: string | null) => {
    this.selectedImageId = imageId || null;
  };

  findImageById = (imageId: string | null) => {
    return this.images.findImageById(imageId);
  };
}

export default Plan;
