import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right-xs.svg';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import convertHoursToDaysAndHours from '../../utils/convert/convertHoursToDaysAndHours';
import PriceFormatter from '../../utils/priceFormatter';
import Loader from '../UI/Loader';
import Typography from '../UI/Typography';

interface IProps {
  amountFormatter?: PriceFormatter;
  plan?: Plan;
}

const BalanceWithAmountHoursLeft: React.FC<IProps> = observer(({amountFormatter, plan}) => {
  const {t} = useTranslation();
  const {serverOptions, billingStore} = useStore();
  const [balanceWithAmountHoursLeft, setBalanceWithAmountHoursLeft] = React.useState(0);
  const previousAmountPointsRef = React.useRef<number>(amountFormatter?.points || 0);
  const didMountRef = React.useRef<boolean>(false);
  const debounceTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await serverOptions.loadPaymentOptions({amountUsd: amountFormatter?.dollars, planId: plan?.id});
      setBalanceWithAmountHoursLeft(res?.hoursLeftWithGivenAmount || 0);
      setLoading(false);
    };

    if (!didMountRef.current || previousAmountPointsRef.current !== amountFormatter?.points) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchData();
        previousAmountPointsRef.current = amountFormatter?.points || 0;
        didMountRef.current = true;
      }, 300);
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [serverOptions, amountFormatter, plan]);

  const {days, hours} = React.useMemo(
    () => convertHoursToDaysAndHours(balanceWithAmountHoursLeft),
    [balanceWithAmountHoursLeft],
  );

  const balanceWithAmountFormatter = React.useMemo(() => {
    const totalPoints = Long.fromNumber(billingStore.balanceFormatter.points + (amountFormatter?.points || 0)); // Total points

    const totalDollars = totalPoints.toNumber() / 10000;

    const formatted = `$${totalDollars.toFixed(2)}`;

    return new PriceFormatter({
      points: totalPoints,
      formatted: formatted,
    });
  }, [billingStore.balanceFormatter.points, amountFormatter?.points]);

  return (
    <div className="balance-with-amount-hours-left">
      <Typography variant="body3">{t('component_payment_viewer_will_keep_servers_running_with')}</Typography>
      <Typography variant="subtitle1" gutterBottom>
        {balanceWithAmountFormatter.formatted}
      </Typography>
      <Typography variant="body3">{t('component_payment_viewer_all_active_servers_and_new')}</Typography>

      <div className="balance-with-amount-hours-left__amount">
        <Loader className="balance-with-amount-hours-left__loader" size={10} loading={loading} />
        <Typography variant="body3">{t('component_payment_viewer_will_run_for')}</Typography>
        <ArrowRight className="balance-with-amount-hours-left__icon" />
        <Typography sx={{opacity: loading ? 0.1 : 1}} variant="subtitle2">
          {t('component_payment_viewer_will_working_days_and_hours', {days, hours})}
        </Typography>
      </div>
    </div>
  );
});

export default BalanceWithAmountHoursLeft;
