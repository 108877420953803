import React from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useStore} from '../stores/AppStore';
import {DOMAIN_NAME_TO_SEARCH_STORE_KEY} from '../stores/Domain/DomainsStore';
import browserStorage from '../utils/browserStorage';
import {navigateToDomains, navigateToSetupServerPlan} from './navigateTo';
import Paths from './Paths';

export function useNavigateByGetParams() {
  const location = useLocation();
  const {authStore, planView} = useStore();
  const navigate = useNavigate();

  const domainNameToSearch = browserStorage.getItem(DOMAIN_NAME_TO_SEARCH_STORE_KEY);

  const hasNavigateToSetupServerPlan = React.useRef(false);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const domainName = decodeURIComponent(params.get('searchDomain') || '');
    const planId = decodeURIComponent(params.get('serverPlanId') || '');

    if (domainName) {
      browserStorage.setItem(DOMAIN_NAME_TO_SEARCH_STORE_KEY, domainName);
      navigate(location.pathname, {replace: true});
    }

    if (planId) {
      planView.setActivePlanId(planId, true);
      navigate(location.pathname, {replace: true});
    }
  }, [location, planView, navigate]);

  React.useEffect(() => {
    if (domainNameToSearch && authStore.isLoggedIn && location.pathname !== Paths.Domains) {
      navigateToDomains();
    }

    if (
      planView.activePlanId &&
      planView.isPlanIdFromLandingRedirect &&
      authStore.isLoggedIn &&
      location.pathname !== Paths.CreateServer
    ) {
      navigateToSetupServerPlan(planView.activePlanId, hasNavigateToSetupServerPlan.current);
      hasNavigateToSetupServerPlan.current = true;
    }
  }, [domainNameToSearch, planView, authStore.isLoggedIn, location.pathname]);
}

export default useNavigateByGetParams;
