import {Box, Button, FormControlLabel, SelectChangeEvent, Typography, styled} from '@mui/material';
import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BackupIcon} from '../../../assets/icons/backup.svg';
import {ReactComponent as ProtectionIcon} from '../../../assets/icons/protection.svg';
import RegionLabel from '../../../components/RegionLabel';
import MenuItem from '../../../components/UI/MenuItem';
import PriceRangeSlider from '../../../components/UI/PriceRangeSlider';
import RangeSlider from '../../../components/UI/RangeSlider';
import Selector from '../../../components/UI/Selector';
import {Switch} from '../../../components/UI/Switch';
import {PLAN_MONTHLY_PRICE_MIN} from '../../../constants';
import {useStore} from '../../../stores/AppStore';
import debounce from '../../../utils/debounce';
import {formatBytes} from '../../../utils/format/formatBytes';

const SwitchBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  textWrap: 'nowrap',
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.border.primary}`,
  '& .Mui-disabled svg': {
    fill: theme.palette.body.placeholder,
  },
  '& svg': {
    fill: theme.palette.body.primary,
    marginRight: theme.spacing(2),
  },
  '& .MuiFormControlLabel-root': {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
  },
  '& .MuiSwitch-root': {
    marginLeft: 'auto',
  },
  '& .MuiTypography-root': {
    display: 'flex',
    alignItems: 'end',
  },
}));

const PlansFilter: React.FC = observer(() => {
  const {t} = useTranslation();
  const {serverOptions, plansStore} = useStore();

  const {filter} = plansStore;

  const handleChangeProvider = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value !== 'null' ? (e.target.value as string) : null;
    serverOptions.changeProvider(value || null);
  };

  const handleChangeCpuModel = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value !== 'null' ? (e.target.value as string) : null;
    serverOptions.changeCpuModel(value || null);
  };

  const handleChangeGpuModel = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value !== 'null' ? (e.target.value as string) : null;
    serverOptions.changeGpuModel(value || null);
  };

  const handleChangeRegion = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value !== 'null' ? (e.target.value as string) : null;
    serverOptions.changeRegion(value || null);
  };

  const regionsList = React.useMemo(() => {
    const selectedId = serverOptions.providers.selectedId;

    if (!selectedId) {
      return serverOptions.regions.list;
    }

    return serverOptions.regions.list.filter(({providerIds}) => providerIds && providerIds.includes(selectedId));
  }, [serverOptions.providers.selectedId, serverOptions.regions.list]);

  return (
    <div className="plans-filter">
      <div className="plans-filter__row plans-filter__row--head">
        <Typography variant="body1">{t('create_server_filters_title')}</Typography>
        <Button variant="outlined" size="small" onClick={filter.reset} disabled={!filter.isFiltersModified}>
          {t('create_server_filters_reset_button')}
        </Button>
      </div>
      <div className={cn('plans-filter__body custom-scroll')}>
        <div className="plans-filter__row">
          <PriceRangeSlider
            label={t('create_server_filters_price_label')}
            value={filter.monthlyPriceRange}
            min={PLAN_MONTHLY_PRICE_MIN}
            max={filter.maxMonthlyRateUsd}
            onChange={debounce(filter.onChangeMonthlyPriceRange)}
          />
        </div>
        <div className="plans-filter__row">
          <Selector
            label={t('create_server_filters_providers_label')}
            value={`${serverOptions.providers.selectedId}`}
            onChange={handleChangeProvider}
          >
            <MenuItem value="null">{t('create_server_filters_providers_any_label')}</MenuItem>
            {serverOptions.providers.list.map(({id, title}) => (
              <MenuItem key={id} value={id || ''}>
                {title}
              </MenuItem>
            ))}
          </Selector>
        </div>
        <div className="plans-filter__row">
          <Selector
            label={t('create_server_filters_regions_label')}
            value={`${serverOptions.regions.selectedId}`}
            onChange={handleChangeRegion}
          >
            <MenuItem value="null">{t('create_server_filters_regions_any_label')}</MenuItem>
            {regionsList.map((region) => (
              <MenuItem key={region.id} value={region.id || ''}>
                <RegionLabel regionOrDatacenter={region} />
              </MenuItem>
            ))}
          </Selector>
        </div>
        {serverOptions.isGpuTabActive ? (
          <div className="plans-filter__row">
            <Selector
              label={t('create_server_filters_gpu_model_label')}
              value={`${serverOptions.gpuModels.selectedId}`}
              onChange={handleChangeGpuModel}
            >
              <MenuItem value="null">{t('create_server_filters_gpu_model_any_label')}</MenuItem>
              {serverOptions.gpuModels.list.map((model) => (
                <MenuItem key={model} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Selector>
          </div>
        ) : (
          <div className="plans-filter__row">
            <Selector
              label={t('create_server_filters_cpu_model_label')}
              value={`${serverOptions.cpuModels.selectedId}`}
              onChange={handleChangeCpuModel}
            >
              <MenuItem value="null">{t('create_server_filters_cpu_model_any_label')}</MenuItem>
              {serverOptions.cpuModels.list.map((model) => (
                <MenuItem key={model} value={model}>
                  {model}
                </MenuItem>
              ))}
            </Selector>
          </div>
        )}
        <div className="plans-filter__row">
          <RangeSlider
            valueIdx={filter.cpuRangeIdx}
            dataList={filter.cpuOptions}
            label={t('create_server_filters_cpu_cores_label')}
            renderListItemLabel={(value) => t('create_server_filters_cpu_cores_item_label', {value})}
            onChange={debounce(filter.onChangeCpuRange)}
          />
        </div>

        <div className="plans-filter__row">
          <RangeSlider
            valueIdx={filter.ramRangeIdx}
            dataList={filter.ramOptions}
            label={t('create_server_filters_ram_memory_label')}
            renderListItemLabel={(value) => formatBytes(value)}
            leftSelectProps={{
              renderValue: (value) => t('create_server_filters_from_label', {value: formatBytes(value)}),
            }}
            rightSelectProps={{
              renderValue: (value) => t('create_server_filters_to_label', {value: formatBytes(value)}),
            }}
            onChange={debounce(filter.onChangeRamRange)}
          />
        </div>
        <div className="plans-filter__row">
          <RangeSlider
            valueIdx={filter.storageRangeIdx}
            dataList={filter.storageOptions}
            renderListItemLabel={(value) => formatBytes(value)}
            label={t('create_server_filters_disk_space_label')}
            leftSelectProps={{
              renderValue: (value) => t('create_server_filters_from_label', {value: formatBytes(value)}),
            }}
            rightSelectProps={{
              renderValue: (value) => t('create_server_filters_to_label', {value: formatBytes(value)}),
            }}
            onChange={debounce(filter.onChangeStorageRange)}
          />
        </div>
        <div className="plans-filter__row">
          <RangeSlider
            valueIdx={filter.transferRangeIdx}
            label={t('create_server_filters_bandwidth_label')}
            dataList={filter.transferOptions}
            renderListItemLabel={(value) => formatBytes(value)}
            onChange={debounce(filter.onChangeTransferRange)}
          />
        </div>
        <SwitchBox>
          <FormControlLabel
            control={<Switch />}
            labelPlacement="start"
            disabled
            label={
              <>
                <BackupIcon />
                {t('create_server_filters_backups_label')}
              </>
            }
          />
        </SwitchBox>
        <SwitchBox>
          <FormControlLabel
            control={<Switch />}
            labelPlacement="start"
            disabled
            label={
              <>
                <ProtectionIcon />
                {t('create_server_filters_ddos_protection_label')}
              </>
            }
          />
        </SwitchBox>
      </div>
    </div>
  );
});

export default PlansFilter;
