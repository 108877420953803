import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {ca2, ca2instances, ca2servers, ca2types} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import {Plan} from '../Plan';
import Instance from './Instance';
import {InstanceFilter} from './InstanceFilter';

export class InstancesStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.wsApi.on('instances.updatedInstances', this.onUpdateInstances_);
  }

  filter: InstanceFilter = new InstanceFilter(this);

  @observable private plansMap_: Record<string, Plan> = {};

  @observable instances: Instance[] = [];
  @observable isInit: boolean = false;

  @computed get filteredInstances() {
    return this.filter.filteredInstances;
  }

  @computed get hasInstances(): boolean {
    return !!this.instances.length;
  }

  @action reset = () => {
    this.instances = [];
    this.isInit = false;
  };

  findInstanceById = (instanceId?: number | null) => {
    return this.instances.find(({id}) => instanceId && instanceId === id) || null;
  };

  findInstanceByPlanId = (id?: string | null) => {
    return this.instances.find(({planId}) => id && id === planId) || null;
  };

  findPlan = (planId?: string | null) => {
    if (!planId) {
      return null;
    }

    return this.plansMap_[planId] || null;
  };

  private hasLoadedPlan_ = (planId?: string | null) => {
    return !!this.findPlan(planId);
  };

  private setAndInitPlan_ = async (rawPlan?: ca2types.IServerPlan | null) => {
    if (!rawPlan || this.hasLoadedPlan_(rawPlan.id)) {
      return;
    }

    const plan = new Plan(rawPlan, this.app.plansStore);
    await plan.init();

    runInAction(() => {
      if (plan.id) {
        this.plansMap_[plan.id] = plan;
      }
    });
  };

  @action init = async () => {
    if (this.isInit) {
      return;
    }

    await this.loadInstances_();

    this.isInit = true;
  };

  private loadInstances_ = async () => {
    const {res} = await this.request({
      instances: {
        instanceList: {
          sortOrder: ca2instances.InstanceSortOrder.ISO_CREATED_AT_DESC,
        },
      },
    });

    if (res?.instances?.instanceList) {
      await this.loadPlans_(res.instances.instanceList.items);
      this.processLoadInstances_(res.instances.instanceList);
    }
  };

  @action private processLoadInstances_ = (res: ca2instances.IInstanceListResponse) => {
    if (res.items) {
      this.instances = res.items.map((rawInstance) => new Instance(rawInstance, this));
    }
  };

  reloadInstances = () => {
    this.loadInstances_();
  };

  private loadPlans_ = async (instances?: ca2types.IInstance[] | null) => {
    const planIds = (instances?.map(({planId}) => planId).filter(Boolean) as string[]) || [];

    if (!planIds.length) {
      return;
    }

    const {res} = await this.request({
      servers: {
        plansList: {
          ids: planIds,
        },
      },
    });

    if (res?.servers?.plansList) {
      await this.processLoadPlans_(res.servers.plansList);
    }
  };

  @action private processLoadPlans_ = async (res: ca2servers.IPlansListResponse) => {
    if (res.items) {
      const plans = res.items.map((raw) => new Plan(raw, this.app.plansStore));
      await Promise.all(plans.map((plan) => plan.init()));

      for (const plan of plans) {
        if (plan.id) {
          this.plansMap_[plan.id] = plan;
        }
      }
    }
  };

  private createInstanceRequest_ = (data: ca2instances.ICreateInstanceRequest) => {
    const requestData: ca2.IClientRequest = {
      instances: {
        createInstance: data,
      },
    };

    if (!this.hasLoadedPlan_(data.planId)) {
      requestData.servers = {
        plansList: {
          ids: data.planId ? [data.planId] : [],
        },
      };
    }

    return this.request(requestData);
  };

  validateInstanceRequestData = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data, dryRun: true});
    return {error, res: res?.instances?.createInstance};
  };

  createInstance = async (data: ca2instances.ICreateInstanceRequest) => {
    const {res, error} = await this.createInstanceRequest_({...data});

    if (res) {
      await this.processCreateInstance_(res);
    }

    return {error, res: res?.instances?.createInstance};
  };

  private processCreateInstance_ = async (res: ca2.IServerResponse) => {
    await this.setAndInitPlan_(res.servers?.plansList?.items?.[0]);

    runInAction(() => {
      if (res?.instances?.createInstance?.instance) {
        this.instances = [...this.instances, new Instance(res.instances.createInstance.instance, this)];
      }
    });
  };

  @action deleteInstanceProcess_ = (instanceId: number | null) => {
    this.instances = this.instances.filter(({id}) => id !== instanceId);
  };

  resizeInstance = async (instance: Instance, planId: string | null) => {
    const {res, error} = await this.request({
      instances: {
        resizeInstance: {
          instanceId: instance.id,
          planId,
        },
      },
      servers: {
        plansList: {
          ids: planId ? [planId] : [],
        },
      },
    });

    if (res) {
      await this.processResizeInstance_(res);
    }

    return {error, res: res?.instances?.resizeInstance};
  };

  processResizeInstance_ = async (res: ca2.IServerResponse) => {
    await this.setAndInitPlan_(res.servers?.plansList?.items?.[0]);

    if (res.instances?.resizeInstance?.instance) {
      this.onUpdateInstances_([res.instances.resizeInstance.instance]);
    }
  };

  private onUpdateInstances_ = (rawInstances: ca2types.IInstance[]) => {
    for (const raw of rawInstances) {
      const foundInstance = this.findInstanceById(raw.id);
      foundInstance?.update(raw);
    }
  };
}

export default InstancesStore;
