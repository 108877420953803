import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {generatePath, useNavigate, useParams} from 'react-router';
import {animated} from 'react-spring';
import {ca2types} from '../../api/proto';
import Div100vh from '../../components/Div100vh';
import DomainContactsViewer from '../../components/DomainContactsViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import Paths, {ProfileRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import ProfileForm from './ProfileForm';
import ProfilePageHead from './ProfilePageHead';
import SetOrChangePasswordForm from './SetOrChangePasswordForm';
import VerifyCurrentAndNewEmailForm from './VerifyCurrentAndNewEmailForm';

export enum ProfilePageTab {
  Account = 'account',
  ChangeEmail = 'changeEmail',
  ChangePassword = 'changePassword',
  ContactInformation = 'contactInformation',
}

const tabsOrder: Record<ProfilePageTab, number> = {
  [ProfilePageTab.Account]: 1,
  [ProfilePageTab.ChangeEmail]: 2,
  [ProfilePageTab.ChangePassword]: 3,
  [ProfilePageTab.ContactInformation]: 4,
};

const isTabValid = (value: unknown): value is ProfilePageTab =>
  typeof value === 'string' && Object.values(ProfilePageTab).includes(value as ProfilePageTab);

export const ProfilePage: React.FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams<ProfileRouteParams>();
  const {userStore, notification} = useStore();

  const [currentTab, setCurrentTab] = useState(ProfilePageTab.Account);
  const [prevTab, setPrevTab] = useState(ProfilePageTab.Account);
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [codeParams, setCodeParams] = useState<ca2types.ICode | null>(null);

  const handleChangeTab = useCallback(
    (tab: ProfilePageTab) => {
      setPrevTab(currentTab);
      setCurrentTab(tab);
      navigate(generatePath(Paths.Profile, {tabType: tab}));
    },
    [navigate, currentTab],
  );

  const handleSubmitUpdateEmail = async (email: string) => {
    if (!userStore.email) {
      return;
    }

    setNewEmail(email);
    const {error, res} = await userStore.updateEmail(userStore.email);

    if (error) {
      notification.error(error.message);
    } else if (res?.code) {
      setCodeParams(res.code);
      handleChangeTab(ProfilePageTab.ChangeEmail);
    }
  };

  useEffect(() => {
    if (isTabValid(params.tabType)) {
      if (params.tabType === ProfilePageTab.ChangeEmail && !codeParams) {
        handleChangeTab(ProfilePageTab.Account);
      } else {
        setCurrentTab(params.tabType);
      }
    }
  }, [params.tabType, codeParams, handleChangeTab]);

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  const renderTab = (styles: any, step: number) => {
    switch (step) {
      case tabsOrder[ProfilePageTab.Account]:
        return (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <ProfileForm
              onSubmitUpdateEmail={handleSubmitUpdateEmail}
              onClickChangePassword={() => handleChangeTab(ProfilePageTab.ChangePassword)}
            />
          </animated.div>
        );
      case tabsOrder[ProfilePageTab.ChangeEmail]:
        return newEmail && codeParams ? (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <VerifyCurrentAndNewEmailForm
              newEmail={newEmail}
              codeParams={codeParams}
              switchToAccountTab={() => handleChangeTab(ProfilePageTab.Account)}
            />
          </animated.div>
        ) : null;
      case tabsOrder[ProfilePageTab.ChangePassword]:
        return (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <SetOrChangePasswordForm
              hasPassword={!!userStore.profile?.hasPassword}
              switchToAccountTab={() => handleChangeTab(ProfilePageTab.Account)}
            />
          </animated.div>
        );
      case tabsOrder[ProfilePageTab.ContactInformation]:
        return (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <DomainContactsViewer />
          </animated.div>
        );
      default:
        return null;
    }
  };

  return (
    <Div100vh className="page page--billing">
      <ProfilePageHead
        currentTab={currentTab}
        onChange={handleChangeTab}
        onClickBackButton={() => handleChangeTab(ProfilePageTab.Account)}
      />
      <div className="page__inner">{transitions(renderTab)}</div>
    </Div100vh>
  );
});

export default ProfilePage;
