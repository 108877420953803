import {Box, styled} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {observer} from 'mobx-react';
import React from 'react';
import {useStore} from '../../../stores/AppStore';

const Container = styled(Box)(({theme}) => ({
  display: 'inline-flex',
  padding: theme.spacing(3),
  background: theme.palette.backgroundPrimary,
  borderBottom: `1px solid ${theme.palette.border.primary}`,
}));

interface IProps {
  onChange?(classId: string): void;
}

export const ServerTabs: React.FC<IProps> = observer((props) => {
  const {
    serverOptions: {tabs},
  } = useStore();

  const handleChange = (_: React.SyntheticEvent, classId: string) => {
    classId && props.onChange?.(classId);
  };

  if (!tabs.selectedId) {
    return null;
  }

  return (
    <Container>
      <Tabs value={tabs.selectedId} onChange={handleChange}>
        {tabs.list.map(({id, title}) => (
          <Tab key={id} label={title} value={id} />
        ))}
      </Tabs>
    </Container>
  );
});

export default ServerTabs;
