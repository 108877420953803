import {action, computed, makeObservable, observable} from 'mobx';
import Instance from './Instance';
import InstancesStore from './InstancesStore';

export class InstanceFilter {
  constructor(public instancesStore: InstancesStore) {
    makeObservable(this);
  }

  @observable searchValue: string = '';
  @observable private filteredInstances_: Instance[] = [];

  @computed private get instances_(): Instance[] {
    return this.instancesStore.instances;
  }

  @computed get filteredInstances() {
    if (this.searchValue) {
      return this.filteredInstances_;
    }

    return this.instances_;
  }

  @action private setFilteredInstances_ = (instance: Instance[]) => {
    this.filteredInstances_ = instance;
  };

  @action setSearchValue = (search: string) => {
    const trimmed = search.trim();
    this.searchValue = trimmed;

    if (trimmed) {
      this.find_();
    } else {
      this.reset_();
    }
  };

  private find_ = () => {
    const filtered = this.filterBySearchValue_(this.instances_);

    this.setFilteredInstances_(filtered);
  };

  private reset_ = () => {
    this.setFilteredInstances_(this.instances_);
  };

  private filterBySearchValue_ = (instances: Instance[]): Instance[] => {
    if (!this.searchValue) {
      return instances;
    }

    return instances.filter(({ipv4, name, image}) =>
      [ipv4, name, image?.family].some((value) => value?.toLowerCase().includes(this.searchValue.toLowerCase())),
    );
  };

  clearSearch = () => {
    this.setSearchValue('');
  };
}
