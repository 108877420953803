import {useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import {useCallback, useEffect} from 'react';
import {matchPath, useLocation} from 'react-router';
import config from '../../config.json';
import Paths from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';

const ChatWidget = observer(() => {
  const {lightThemeChosen, userStore} = useStore();
  const theme = useTheme();
  const location = useLocation();

  const hideWidgetButton = [Paths.Billing, Paths.DomainInfo].some((path) => matchPath(path, location.pathname));

  const updateUserMetadata = useCallback(() => {
    if (userStore.profile) {
      const metadata: string[] = [];

      if (userStore.profile.id) {
        metadata.push(`User ID: ${userStore.profile.id.toString()}`);
      }

      if (userStore.profile.fullName) {
        metadata.push(`Full name: ${userStore.profile.fullName}`);
      }

      if (userStore.profile.phone) {
        metadata.push(`Phone: ${userStore.profile.phone}`);
      }

      window.owcWidget?.set('visitor.update', {
        email: userStore.profile.email ?? '',
        metadata,
      });
    }
  }, [userStore.profile]);

  const initializeChatWidget = useCallback(() => {
    if (window.owcWidget) {
      window.owcWidget.set(lightThemeChosen ? 'box.light' : 'box.dark');
      window.owcWidget.set(hideWidgetButton ? 'box.hide' : 'box.show');

      if (theme.palette.brand.primary) {
        window.owcWidget.set('theme.setColor', theme.palette.brand.primary);
      }

      updateUserMetadata();
    }
  }, [lightThemeChosen, theme, hideWidgetButton, updateUserMetadata]);

  const loadChatWidgetScript = useCallback(() => {
    if (!document.getElementById('chat-widget-script')) {
      const script = document.createElement('script');
      script.id = 'chat-widget-script';
      script.async = true;
      script.src = `//newadmin.ozekon.io/widget/loader?id=${config.CHAT_WIDGET_API_KEY}`;

      script.onload = () => {
        const interval = setInterval(() => {
          if (window.owcWidget) {
            initializeChatWidget();
            clearInterval(interval);
          }
        }, 500);
      };

      document.body.appendChild(script);
    } else {
      const checkWidgetLoaded = setInterval(() => {
        if (window.owcWidget) {
          initializeChatWidget();
          clearInterval(checkWidgetLoaded);
        }
      }, 500);
    }
  }, [initializeChatWidget]);

  useEffect(() => {
    if (window.owcWidget) {
      initializeChatWidget();
    } else {
      loadChatWidgetScript();
    }
  }, [initializeChatWidget, loadChatWidgetScript]);

  return null;
});

export default ChatWidget;
