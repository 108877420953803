import {action, makeObservable, observable} from 'mobx';
import Network from '../api/network';
import {ca2} from '../api/proto';
import {NetworkResponse} from '../api/types';
import WsNetwork from '../api/wsNetwork';
import {AppStore} from './AppStore';

export class APILayer {
  get api(): Network {
    return this.app.api;
  }

  get wsApi(): WsNetwork {
    return this.app.wsApi;
  }

  constructor(public app: AppStore) {
    makeObservable(this);
  }

  @observable loading = false;

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  request = async <T extends ca2.IServerResponse>({
    token,
    ...restData
  }: ca2.IClientRequest): Promise<NetworkResponse<T>> => {
    this.setLoading(true);

    try {
      const response = await this.api.request<T>({
        token: token || this.app.authStore.getToken(),
        ...restData,
      });
      return response;
    } finally {
      this.setLoading(false);
    }
  };
}

export default APILayer;
