import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BackupIcon} from '../../../assets/icons/backup.svg';
import {ReactComponent as CpuIcon} from '../../../assets/icons/cpu.svg';
import {ReactComponent as RamIcon} from '../../../assets/icons/ram.svg';
import {ReactComponent as OptionIcon} from '../../../assets/icons/server-option.svg';
import {ReactComponent as SpeedIcon} from '../../../assets/icons/speed.svg';
import {ReactComponent as StorageIcon} from '../../../assets/icons/storage.svg';
import ProviderLogo from '../../../components/ProviderLogo';
import Typography from '../../../components/UI/Typography';
import {Plan} from '../../../stores/Plan';
import {BENEFIT_BACKUP_ID} from '../../../stores/ServerOptions';

interface IProps {
  plan: Plan;
}

export const ActivatedPlanCard: React.FC<IProps> = ({plan}) => {
  const {t} = useTranslation();
  return (
    <div className="activated-plan-card">
      <div className="activated-plan-card__row">
        <Typography className="activated-plan-card__title" variant="label3">
          {t('create_server_activated_plan_card_provider_label')}
        </Typography>
        <div className="activated-plan-card__provider-title">
          <ProviderLogo providerId={plan.providerId} />
          <Typography variant="body1">{plan.provider?.title}</Typography>
        </div>
      </div>
      <div className="activated-plan-card__row">
        <Typography className="activated-plan-card__title" variant="label3">
          {t('create_server_activated_plan_card_server_label')}
        </Typography>

        <div className="activated-plan-card__hardware-info">
          <div className="activated-plan-card__hardware-info-item">
            <div className="activated-plan-card__hardware-info-icon">
              <CpuIcon />
            </div>
            <Typography variant="body2">{plan.cpuInfo}</Typography>
          </div>
          <div className="activated-plan-card__hardware-info-item">
            <div className="activated-plan-card__hardware-info-icon">
              <RamIcon />
            </div>
            <Typography variant="body2">{plan.ramSizeFormatted}</Typography>
          </div>
          <div className="activated-plan-card__hardware-info-item">
            <div className="activated-plan-card__hardware-info-icon">
              <StorageIcon />
            </div>
            <Typography variant="body2">{plan.storageSizeFormatted}</Typography>
          </div>
          <div className="activated-plan-card__hardware-info-item">
            <div className="activated-plan-card__hardware-info-icon">
              <SpeedIcon />
            </div>
            <Typography variant="body2">{plan.transferFormatted}</Typography>
          </div>
        </div>
      </div>
      <div className="activated-plan-card__row">
        <Typography className="activated-plan-card__title" variant="label3">
          {t('create_server_activated_plan_card_additional_services_label')}
        </Typography>

        <div className="activated-plan-card__server-benefits">
          {plan.benefits.map(({id, title}) => (
            <div key={id} className="activated-plan-card__server-benefits-item">
              <div className="activated-plan-card__server-benefits-icon">
                {id === BENEFIT_BACKUP_ID ? <BackupIcon /> : <OptionIcon />}
              </div>
              <Typography variant="body2">{title}</Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivatedPlanCard;
