import {observer} from 'mobx-react';
import React from 'react';
import {useParams} from 'react-router';
import {animated} from 'react-spring';
import {getApiCreateInstanceError} from '../../api/getApiError';
import {ca2instances} from '../../api/proto';
import Div100vh from '../../components/Div100vh';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToCreateServer, navigateToSetupServerPlan} from '../../routes/navigateTo';
import {SetupServerPlanRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import ChoosePlanStep from './ChoosePlanStep/ChoosePlanStep';
import CreateServerConfirmation from './CreateServerConfirmation';
import CreateServerPageTopBar from './CreateServerPageTopBar';
import SetupPlanViewer from './SetupPlanViewier';

export enum CreateServerStep {
  CHOOSE_PLAN,
  SETUP_PLAN,
  PAYMENT,
  CONFIRMATION,
}

export const CreateServerPage: React.FC = observer(() => {
  const {instancesStore, notification, plansStore, planView} = useStore();

  const [stepState, setStepState] = React.useState({
    prevStep: CreateServerStep.CONFIRMATION,
    currentStep: CreateServerStep.CHOOSE_PLAN,
  });

  const [setupPlanFormData, setSetupPlanFormData] = React.useState<ca2instances.ICreateInstanceRequest | null>(null);
  const [createServerResponse, setCreateServerResponse] = React.useState<ca2instances.ICreateInstanceResponse | null>(
    null,
  );

  const clearSelectedPlanData = React.useCallback(() => {
    planView.clearActivePlan();

    setSetupPlanFormData(null);
    setCreateServerResponse(null);
  }, [planView]);

  const handleSelectPlan = React.useCallback((plan: Plan) => {
    plan.id && navigateToSetupServerPlan(plan.id);
  }, []);

  const switchToStep = React.useCallback((nexStep: CreateServerStep) => {
    setStepState((prev) => ({prevStep: prev.currentStep, currentStep: nexStep}));
  }, []);

  const switchToConfirmation = () => {
    switchToStep(CreateServerStep.CONFIRMATION);
  };

  const switchToChoosePlan = React.useCallback(() => {
    navigateToCreateServer();
    clearSelectedPlanData();
    switchToStep(CreateServerStep.CHOOSE_PLAN);
  }, [clearSelectedPlanData, switchToStep]);

  const backButtonHandler = React.useCallback(() => {
    if (stepState.currentStep === CreateServerStep.CHOOSE_PLAN) return;

    const nextStep =
      stepState.currentStep === CreateServerStep.PAYMENT ? CreateServerStep.SETUP_PLAN : stepState.currentStep - 1;

    switchToStep(nextStep);

    if (nextStep === CreateServerStep.CHOOSE_PLAN) {
      switchToChoosePlan();
    }
  }, [stepState, switchToStep, switchToChoosePlan]);

  const handleSubmitSetupPlanForm = React.useCallback(
    (formData: ca2instances.ICreateInstanceRequest) => {
      setSetupPlanFormData(formData);

      switchToStep(CreateServerStep.PAYMENT);
    },
    [switchToStep],
  );

  const handleCreateServerInstance = async () => {
    if (!setupPlanFormData) {
      return;
    }

    const {res, error} = await instancesStore.createInstance(setupPlanFormData);

    if (error) {
      notification.error(error.message);
    }

    if (res) {
      const {errors} = res;

      if (errors?.length) {
        errors.forEach((error) => {
          const errorMessage = getApiCreateInstanceError(error);
          notification.error(errorMessage);
        });

        return;
      }

      setCreateServerResponse(res);
      switchToConfirmation();
    }
  };

  const params = useParams<SetupServerPlanRouteParams>();

  React.useEffect(() => {
    if (params.planId) {
      planView.setActivePlanId(params.planId);

      setStepState({
        prevStep: CreateServerStep.CHOOSE_PLAN,
        currentStep: CreateServerStep.SETUP_PLAN,
      });
    } else {
      clearSelectedPlanData();
      switchToStep(CreateServerStep.CHOOSE_PLAN);
    }

    return () => {
      clearSelectedPlanData();
    };
  }, [params, planView, switchToStep, clearSelectedPlanData]);

  const transitions = useOrderTransition(stepState);

  const renderStepComponent = (step: CreateServerStep) => {
    switch (step) {
      case CreateServerStep.CHOOSE_PLAN:
        return (
          <ChoosePlanStep plans={plansStore.plans} plansLoading={plansStore.loading} onSelectPlan={handleSelectPlan} />
        );
      case CreateServerStep.SETUP_PLAN:
        return planView.activePlan ? (
          <SetupPlanViewer
            plan={planView.activePlan}
            initialValues={setupPlanFormData}
            onSwitchToChoosePlan={switchToChoosePlan}
            onSubmit={handleSubmitSetupPlanForm}
          />
        ) : null;
      case CreateServerStep.PAYMENT:
        return planView.activePlan ? (
          <PaymentViewer
            orderInstance={planView.activePlan}
            onCancelPaymentOrder={switchToChoosePlan}
            onClickPayButton={handleCreateServerInstance}
          />
        ) : null;
      case CreateServerStep.CONFIRMATION:
        return planView.activePlan && createServerResponse ? (
          <CreateServerConfirmation
            plan={planView.activePlan}
            createServerResponse={createServerResponse}
            onCancelPaymentOrder={switchToChoosePlan}
          />
        ) : null;
    }
  };

  return (
    <Div100vh className="page page--create-server">
      <CreateServerPageTopBar currentStep={stepState.currentStep} onBack={backButtonHandler} />

      <div className="page__inner">
        {transitions((styles, step) => (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            {renderStepComponent(step)}
          </animated.div>
        ))}
      </div>
    </Div100vh>
  );
});

export default CreateServerPage;
