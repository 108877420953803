import {Box, Button, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {useStore} from '../../stores/AppStore';
import {ModalType} from '../../stores/ModalsStore';
import Typography from '../UI/Typography';

export const EmptyDomainContactsTitle: React.FC = observer(() => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {modals} = useStore();

  const handleAddNewContact = () => {
    modals.open(ModalType.EDIT_DOMAIN_CONTACT);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 500,
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          {t('component_domain_contacts_viewer_no_contacts_title')}
        </Typography>
        <Typography variant="body1" color="body.tertiary" sx={{marginBottom: theme.spacing(4)}}>
          {t('component_domain_contacts_viewer__no_contacts_text')}
        </Typography>

        <Button
          size="large"
          endIcon={<PlusIcon style={{fill: theme.palette.body.tint}} />}
          onClick={handleAddNewContact}
        >
          {t('component_domain_contacts_viewer_add_contact_button')}
        </Button>
      </Box>
    </Box>
  );
});

export default EmptyDomainContactsTitle;
