import React from 'react';
import {useLocation} from 'react-router';
import {APP_NAME} from '../constants';

const formatSegment = (segment: string): string => {
  if (!isNaN(Number(segment))) return `#${segment}`;

  return segment
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/[-_]/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const MetaLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  const location = useLocation();

  React.useEffect(() => {
    const segments = location.pathname.split('/').filter(Boolean).map(formatSegment);

    const title = segments.length > 0 ? segments.join(' - ') : 'Home';

    document.title = `${title} | ${APP_NAME}`;
  }, [location.pathname]);

  return <>{children}</>;
};

export default MetaLayout;
