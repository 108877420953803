import {observer} from 'mobx-react-lite';

import {useStore} from '../stores/AppStore';

import {useTheme} from '@mui/material';
import {ReactComponent as MaintenanceIcon} from '../assets/icons/maintenance.svg';

const SystemAlertBar = observer(() => {
  const {utils} = useStore();
  const theme = useTheme();

  if (!utils.hasAlertMessage) {
    return null;
  }

  return (
    <div id="system-alert-bar">
      <MaintenanceIcon style={{marginRight: theme.spacing(2), minWidth: 32}} />
      {utils.alertMessage}
    </div>
  );
});

export default SystemAlertBar;
