import React from 'react';

import {Box, useTheme} from '@mui/material';
import {ReactComponent as ArrowDownIcon} from '../../assets/icons/arrow-down-xs.svg';
import {ReactComponent as ArrowUpIcon} from '../../assets/icons/arrow-up-xs.svg';

import {Switch} from '../UI/Switch';
import Typography from '../UI/Typography';
import CookieTable from './CookieTable';
import {ICookieCategory} from './cookieCategories';
import {isNecessaryCookie} from './cookiesStateManager';

interface CookieGroupItemProps {
  cookieGroup: ICookieCategory;
  checked?: boolean;
  onChange?: (cookieGroup: ICookieCategory) => void;
}

export function CookieGroupItem({cookieGroup, checked, onChange}: CookieGroupItemProps) {
  const theme = useTheme();
  const [collapsed, setCollapsed] = React.useState(true);

  const handleChange = () => onChange?.(cookieGroup);
  const handleCollapse = () => setCollapsed(!collapsed);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        backgroundColor: theme.palette.backgroundSecondary,
        borderRadius: '12px',
        border: `1px solid ${theme.palette.border.primary}`,
        padding: theme.spacing(3),
        width: '100%',

        '&:not(:first-of-type)': {
          marginTop: theme.spacing(2),
        },
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%'}}>
        <Typography variant="subtitle2">{cookieGroup.title}</Typography>
        <Typography variant="body2" gutterBottom sx={{mt: theme.spacing(1)}}>
          {cookieGroup.description}
        </Typography>

        {collapsed ? null : <CookieTable className="mt-1" cookies={cookieGroup.cookies} />}

        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            cursor: 'pointer',
            color: theme.palette.brand.primary,
          }}
          variant="body2"
          onClick={handleCollapse}
        >
          {collapsed ? 'Show cookies' : 'Hide cookies'}
          {collapsed ? (
            <ArrowDownIcon style={{fill: theme.palette.brand.primary, marginLeft: theme.spacing(0)}} />
          ) : (
            <ArrowUpIcon style={{fill: theme.palette.brand.primary, marginLeft: theme.spacing(0)}} />
          )}
        </Typography>
      </Box>
      <Box sx={{paddingLeft: theme.spacing(4), marginLeft: 'auto'}}>
        <Switch
          checked={checked || isNecessaryCookie(cookieGroup)}
          onChange={handleChange}
          disabled={isNecessaryCookie(cookieGroup)}
        />
      </Box>
    </Box>
  );
}

export default CookieGroupItem;
