import {makeObservable} from 'mobx';
import {ToastContent, ToastOptions, toast} from 'react-toastify';

interface ITypeToastOptions<TData = unknown> extends Omit<ToastOptions<TData>, 'type'> {}

export class NotificationsStore {
  constructor() {
    makeObservable(this);
  }

  public default = <TData = unknown>(content: ToastContent<TData>, options?: ITypeToastOptions<TData> | undefined) => {
    toast(content, {
      ...options,
      type: 'default',
    });
  };

  public error = <TData = unknown>(content: ToastContent<TData>, options?: ITypeToastOptions<TData> | undefined) => {
    toast(content, {
      ...options,
      type: 'error',
    });
  };
  public info = <TData = unknown>(content: ToastContent<TData>, options?: ITypeToastOptions<TData> | undefined) => {
    toast(content, {
      ...options,
      type: 'info',
    });
  };
  public success = <TData = unknown>(content: ToastContent<TData>, options?: ITypeToastOptions<TData> | undefined) => {
    toast(content, {
      ...options,
      type: 'success',
    });
  };
  public warning = <TData = unknown>(content: ToastContent<TData>, options?: ITypeToastOptions<TData> | undefined) => {
    toast(content, {
      ...options,
      type: 'warning',
    });
  };
}

export default NotificationsStore;
