import {Box, Button, styled, useTheme} from '@mui/material';
import cn from 'classnames';
import {getName} from 'country-list';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiDomainContactDeleteError} from '../../../api/getApiError';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning-red.svg';
import {useStore} from '../../../stores/AppStore';
import DomainContact from '../../../stores/Domain/DomainContact';
import {ModalType} from '../../../stores/ModalsStore';
import CheckboxField from '../../UI/CheckboxField';
import RadioField from '../../UI/RadioField';
import Typography from '../../UI/Typography';

const Container = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '8px',
  width: '100%',
  marginBottom: theme.spacing(2),
  overflow: 'hidden',

  '&.selectable:hover': {
    backgroundColor: theme.palette.backgroundSecondary,
    cursor: 'pointer',
  },

  '&.invalid': {
    borderColor: theme.palette.error.primary,
  },
}));

const Inner = styled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(2),

  '&:last-child': {
    borderTop: `1px solid ${theme.palette.border.primary}`,
  },

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

interface IProps {
  contact: DomainContact;
  editable?: boolean;
  selectable?: boolean;
  checked?: boolean;
  showConnectedDomains?: boolean;
  onSelect?(contact: DomainContact);
  onContactDeleted?(contactId?: number | null);
  hideEditContactButtons?: boolean;
}

export const DomainContactItem: React.FC<IProps> = ({
  contact,
  selectable,
  editable,
  checked,
  showConnectedDomains,
  onSelect,
  onContactDeleted,
  hideEditContactButtons,
}) => {
  const {
    modals,
    notification,
    domainsStore: {contacts},
  } = useStore();

  const theme = useTheme();
  const {t} = useTranslation();

  const deleteContact = async (contactId?: number | null) => {
    if (!contactId) {
      return;
    }

    const {res, error} = await contacts.deleteContact(contactId);

    if (error) {
      notification.error(error.message);
      return;
    }

    if (res?.errors?.length) {
      for (const error of res.errors) {
        const errorMessage = getApiDomainContactDeleteError(error);
        notification.error(errorMessage);
      }

      return;
    }

    notification.success(t('component_domain_contacts_viewer_delete_successfully_notification'));

    onContactDeleted?.(contactId);
  };

  const handleSelect = () => {
    if (selectable && contact) {
      onSelect?.(contact);
    }
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    modals.open(ModalType.EDIT_DOMAIN_CONTACT, {
      domainContact: contact,
    });
  };

  const handleClickDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (contact.associatedDomains.length) {
      modals.open(ModalType.DELETE_DOMAIN_CONTACT, {
        domainContact: contact,
        callbacks: {
          submit: () => {
            onContactDeleted?.(contact.id);
          },
        },
      });

      return;
    }

    modals.open(ModalType.CONFIRM, {
      maxWidth: 'sm',
      headerText: t('modal_confirm_delete_domain_contact_header_text'),
      submitButtonText: t('action_button_delete'),
      submitButtonColor: 'error',
      content: <Typography variant="body1">{t('modal_confirm_delete_domain_contact_text')}</Typography>,
      callbacks: {
        submit: async () => {
          await deleteContact(contact.id);
        },
      },
    });
  };

  const addressString = [
    getName(contact.mailingCountryCode),
    contact.mailingCity,
    contact.mailingAddress,
    contact.mailingState,
    contact.mailingZip,
  ]
    .filter((item) => !!item)
    .join(', ');

  return (
    <Container className={cn({selectable: selectable, invalid: contact.shouldBeFixed})} onClick={handleSelect}>
      {contact.shouldBeFixed ? (
        <Inner sx={{alignItems: 'center', borderBottom: `1px solid ${theme.palette.border.primary}`}}>
          <WarningIcon />
          <Typography sx={{marginLeft: theme.spacing(1)}} variant="subtitle2">
            {t('component_domain_contacts_viewer_invalid_contact')}
          </Typography>
        </Inner>
      ) : null}
      <Inner>
        <Box>
          {selectable && editable ? (
            <RadioField checked={checked} sx={{padding: 0}} disableRipple />
          ) : editable ? (
            <CheckboxField sx={{padding: 0}} checked disabled />
          ) : null}
        </Box>

        <Box
          sx={{
            flex: '1 1 auto',
            paddingLeft: selectable ? theme.spacing(2) : '0px',
            width: selectable || editable ? 'calc(100% - 30px)' : '100%',
          }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              variant="body1"
              sx={{
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(1),
                overflow: 'hidden',
                marginRight: theme.spacing(1),
                width: '100%',
              }}
            >
              <Box
                sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
              >{`${contact.firstName} ${contact.lastName}`}</Box>
            </Typography>

            {hideEditContactButtons ? null : (
              <Box sx={{display: 'flex', gap: theme.spacing(2)}}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{minWidth: 0, paddingX: theme.spacing(2)}}
                  onClick={handleClickEdit}
                >
                  <EditIcon style={{fill: 'currentcolor'}} />
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{minWidth: 0, paddingX: theme.spacing(2)}}
                  onClick={handleClickDelete}
                >
                  <DeleteIcon style={{fill: 'currentcolor'}} />
                </Button>
              </Box>
            )}
          </Box>

          <Typography
            variant="body2"
            color="body.tertiary"
            sx={{
              marginBottom: theme.spacing(0),
            }}
          >
            <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{addressString}</Box>
          </Typography>
          <Typography variant="body2" color="body.tertiary">
            <Box
              sx={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
            >{`${contact.emailAddress}, ${contact.phoneNumber}`}</Box>
          </Typography>
        </Box>
      </Inner>

      {showConnectedDomains && contact.associatedDomains.length ? (
        <Inner sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="body1" color="body.secondary" gutterBottom>
            {t('component_domain_contacts_viewer_connected_domains_title')}
          </Typography>
          <Box sx={{display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap'}}>
            {contact.associatedDomains.map((domain) => (
              <Box
                key={domain.domainId}
                component="span"
                sx={{
                  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                  backgroundColor: theme.palette.backgroundSecondary,
                  borderRadius: '8px',
                }}
              >
                {domain.name}
              </Box>
            ))}
          </Box>
        </Inner>
      ) : null}
    </Container>
  );
};

export default DomainContactItem;
