import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';

export class Currency {
  @observable code: string | null = null;
  @observable private title: string | null = null;
  @observable private rate: ca2types.IAmount | null = null;
  @observable private minAmount: ca2types.IAmount | null = null;
  @observable places: number | null = null;

  @observable rateFormatter: PriceFormatter;
  @observable minAmountFormatter: PriceFormatter;

  constructor(public raw: ca2types.ICurrency) {
    makeObservable(this);
    this.update_(raw);

    this.rateFormatter = new PriceFormatter(this.rate);
    this.minAmountFormatter = new PriceFormatter(this.minAmount);
  }

  @computed get name(): string {
    const code = this.code?.toUpperCase() || '';

    if (!code) {
      return '';
    }

    const networkMap: {[key: string]: string} = {
      USDTT: '(TRC-20)',
      USDTE: '(ERC-20)',
      USDC: '(ERC-20)',
      USDCS: '(SOL)',
    };

    const network = networkMap[code] || '';
    let name_ = code;

    if (['USDTT', 'USDTE'].includes(code)) {
      name_ = 'USDT';
    }

    if (['USDC', 'USDCS'].includes(code)) {
      name_ = 'USDC';
    }

    return `${name_} ${network}`;
  }

  @action
  private update_ = (raw: ca2types.ICurrency) => {
    Object.assign(this, raw);
  };
}

export default Currency;
