import React from 'react';

import AuthIllustration from '../../pages/Auth/AuthIllustration';
import MetaLayout from '../MetaLayout';
import BaseAuthLayout from './BaseAuthLayout';

export const AuthLayout: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <MetaLayout>
      <BaseAuthLayout>
        <div className="auth-layout__inner">
          {children}
          <AuthIllustration />
        </div>
      </BaseAuthLayout>
    </MetaLayout>
  );
};

export default AuthLayout;
